import { Component } from '@angular/core';
import { CompanyService } from '../../services/company.service';
import { map, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { BrandsService } from '../../services/brands.service';

@Component({
  selector: 'lib-company-details',
  templateUrl: './company-details.component.html',
  styleUrl: './company-details.component.scss',
})
export class CompanyDetailsComponent {
  profile: any;
  brands: any;
  constructor(
    private CompanyService: CompanyService,
    private route: ActivatedRoute,
    private brandsService: BrandsService
  ) {}

  ngOnInit() {
    this.CompanyService.getCompanyProfile().subscribe({
      next: (data) => {
        this.profile = data.data;
        console.log('Profile', this.profile);
      },
      error: (error) => {
        console.log('Error while fetching company data', error);
      },
    });
    this.brandsService.getBrands().subscribe({
      next: (data) => {
        this.brands = data.data;
      },
      error: (error) => {
        console.log('Error while fetching brands data', error);
      },
    });
  }
}
