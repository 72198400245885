import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  dateNow = new Date();
  constructor(
    private router: Router,
    private authService: AuthService,
    private toastrService: ToastrService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<boolean>((resolve, reject) => {
      let token = this.authService.currentUserSubject.value.token?.access_token;
      if (!token) {
        this.toastrService.error(
          'You don’t have the right access',
          'Please login to continue'
        );
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 1000);
        resolve(false);
      } else {
        resolve(true);
      }
    });
  }
}
