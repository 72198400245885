import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BrandsService } from '../../../services/brands.service';
import { environment } from '../../../environments/src';

@Component({
  selector: 'lib-brand-details',
  templateUrl: './brand-details.component.html',
  styleUrl: './brand-details.component.scss',
})
export class BrandDetailsComponent {
  brand: any;
  productURL = environment.WEBSITE_URL + 'products/details/';
  constructor(
    private route: ActivatedRoute,
    private brandsService: BrandsService
  ) {}
  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.getBrandById(params.id);
    });
  }

  getBrandById(id: any) {
    this.brandsService.getBrandById(id).subscribe({
      next: (data) => {
        this.brand = data.data;
        console.log('Brand data', this.brand);
      },
      error: (error) => {
        console.log('Error while fetching brand data', error);
      },
    });
  }
}
