<div>
    <div class="container-fluid">
        <div class="my-3 d-flex align-items-center justify-content-between">
            <p class="fs-6 ">
                <a routerLink="/company">
                    Company details
                </a>
                / brands
            </p>
            <div>
                <button (click)="openAddBrandModal()" class="btn btn-primary">
                    Add brand
                </button>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-3" *ngFor="let brand of brands">
                    <lib-brand-card [brand]="brand" (statusUpdated)="getBrands()"></lib-brand-card>
                </div>
            </div>
        </div>
    </div>
</div>