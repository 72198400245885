import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BrandCardComponent } from '../../components/brand-card/brand-card.component';
import { PipesModule } from '../../pipes/pipes.module';
import { BrandDetailsComponent } from './brand-details/brand-details.component';
import { BrandsRoutingModule } from './brands-routing.module';
import { BrandsComponent } from './brands.component';
import { AddBrandComponent } from './add-brand/add-brand.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [BrandsComponent, BrandDetailsComponent, AddBrandComponent],
  imports: [
    CommonModule,
    BrandsRoutingModule,
    BrandCardComponent,
    PipesModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    NgSelectModule,
  ],
})
export class BrandsModule {}
