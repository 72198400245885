<div class="main-wrapper">
    <lib-header></lib-header>
    <div class="main-content" [ngClass]="{'admin': isAdmin}">
        <p>
            Dashboard
        </p>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <lib-sidebar *ngIf="isAdmin"></lib-sidebar>
</div>