<div class="table-responsive custom-table" *ngIf="products?.length">
    <table class="w-100">
        <thead>
            <tr>
                <th>Name</th>
                <th>Image</th>
                <th>Approved</th>
                <th>Price</th>
                <th>Added by</th>
                <th>Percentage</th>
                <th>Quantity</th>
                <th>Brand</th>
                <th>Status</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let product of products">
                <td>
                    <a [href]="productURL + product.product?.slug" target="_blank">
                        <p class="mb-0 text-truncate" [tooltip]="product.product?.name">
                            {{ product.product?.name }}
                        </p>
                    </a>
                </td>
                <td>
                    <div class="img-container">
                        <img class="object-fit-contain" [src]="product.product?.thumb_image | customImage" alt="">
                    </div>
                </td>
                <td>
                    <span class="badge" [ngClass]="product.is_accepted ? 'approved': 'pending'">
                        {{ product.is_accepted ? 'Approved' : 'Pending' }}
                    </span>
                </td>
                <td>
                    {{ product.product?.price?.toFixed(2) }} EGP
                </td>
                <td>
                    {{product.added_by_affiliate? 'You': 'Admin'}}
                </td>
                <td>
                    {{ product.product?.percentage || 0 }} %
                </td>
                <td>
                    {{ product.product?.qty }}
                </td>
                <td>
                    {{ product.product?.brand ? product.product?.brand.name : '--' }}
                    <!-- <div class="form-check form-switch d-inline-block">
                        <label class="form-check-label text-success" for="flexSwitchCheckChecked">
                            Active
                        </label>
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                            checked>
                    </div> -->
                </td>
                <td>
                    <div *ngIf="type == 'pending';else activeProducts">
                        <div *ngIf="product.approved_by_admin" class="form-check form-switch ps-5">
                            <input role="switch" class="form-check-input" type="checkbox"
                                [id]="'activateProduct' + product.id" [checked]="product.is_accepted"
                                (change)="changeStatus(product.product_id)">
                            <label [for]="'activateProduct' + product.id">
                                {{product.is_accepted ? 'Active' : 'Inactive'}}
                            </label>
                        </div>
                    </div>
                    <ng-template #activeProducts>
                        <div class="form-check form-switch ps-5">
                            <input role="switch" class="form-check-input" type="checkbox"
                                [id]="'activateProduct' + product.id" [checked]="product.is_accepted"
                                (change)="changeStatus(product.product_id)">
                            <label [for]="'activateProduct' + product.id">
                                {{product.is_accepted ? 'Active' : 'Inactive'}}
                            </label>
                        </div>
                    </ng-template>
                </td>
                <td>
                    <div class="actions d-flex align-items-center justify-content-center">
                        <a role="button" class="me-2" (click)="confirmDelete(product.product_id)">
                            <i class="flaticon-delete lh-1">
                            </i>
                        </a>
                        <!-- <a role="button" *ngIf="type == 'pending' && product.added_by_admin && !product.is_accepted"
                            (click)="changeStatus(product.product_id)">
                            <i class="flaticon-check text-success lh-1">
                            </i>
                        </a> -->
                    </div>
                    <!-- <a class="me-2">
                        <i class="flaticon-view lh-1">
                        </i>
                    </a>
                    <a>
                        <i class="flaticon-more lh-1">
                        </i>
                    </a> -->

                </td>
            </tr>
        </tbody>
    </table>
</div>
<!-- <div class="footer my-3 d-flex justify-content-between">
    <div>
        <small class="text-secondary  fs-13">Showing 1 to 7 of 100 entries</small>
    </div>
    <div>
        <pagination [totalItems]="total" [maxSize]="12" [(ngModel)]="page" (pageChanged)="loadMore($event)">
        </pagination>
    </div>
</div> -->