<div class="py-3">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4">
                <form [formGroup]="withdrawForm">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group mb-3" *ngIf="bankAccounts.length > 0">
                                <label class="form-label" for="chooseAccount">Choose Account</label>
                                <select name="chooseAccount" id="chooseAccount" class="form-select"
                                    formControlName="account_id">
                                    <option *ngFor="let bankAccount of bankAccounts" [value]="bankAccount.id">
                                        {{bankAccount.account_number}}
                                    </option>
                                </select>
                                <div class="form-control-feedback"
                                    *ngIf="((withdrawForm.get('account_id')?.touched) && (withdrawForm.get('account_id')?.invalid))">
                                    <small *ngIf="withdrawForm.get('account_id')?.errors?.['required']"
                                        class="text-danger">
                                        This field is requird
                                    </small>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" for="withdrawAmount">Withdraw Amount</label>
                                <input type="number" name="withdrawAmount" id="withdrawAmount" class="form-control"
                                    placeholder="Enter withdraw amount" formControlName="amount">
                                <div class="form-control-feedback"
                                    *ngIf="((withdrawForm.get('amount')?.touched) && (withdrawForm.get('amount')?.invalid))">
                                    <small *ngIf="withdrawForm.get('amount')?.errors?.['required']" class="text-danger">
                                        This field is requird
                                    </small>
                                </div>
                            </div>
                            <button class="btn btn-primary w-100" (click)="requestWithdraw()">Request </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-lg-6" *ngIf="info$ |async as info">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div>
                                        <div class="number">
                                            <h2 class="text-truncate">
                                                {{info.data?.totalWithdraw}}
                                            </h2>
                                        </div>
                                        <div class="status">
                                            <h5>
                                                Total Withdraw
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="icon text-center p-1 mw-50 rounded-2">
                                        <i class="flaticon-withdraw"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div>
                                        <div class="number">
                                            <h2 class="text-truncate">
                                                20
                                            </h2>
                                        </div>
                                        <div class="status">
                                            <h5>
                                                Total sales amount
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="icon text-center p-1 mw-50 rounded-2">
                                        <i class="flaticon-price-tag"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div>
                                        <div class="number">
                                            <h2 class="text-truncate">
                                                {{info.data['totalPendingWithdraw ']}}
                                            </h2>
                                        </div>
                                        <div class="status">
                                            <h5>
                                                Pending withdraw
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="icon text-center p-1 mw-50 rounded-2">
                                        <i class="flaticon-expired"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div>
                                        <div class="number">
                                            <h2 class="text-truncate">
                                                {{wallet.available_balance}}
                                            </h2>
                                        </div>
                                        <div class="status">
                                            <h5>
                                                Current Balance
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="icon text-center p-1 mw-50 rounded-2">
                                        <i class="flaticon-wallet"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>