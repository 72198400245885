import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrandDetailsComponent } from './brand-details/brand-details.component';
import { BrandsComponent } from './brands.component';

const routes: Routes = [
  {
    path: '',
    component: BrandsComponent,
  },
  { path: 'brand-details/:id', component: BrandDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BrandsRoutingModule {}
