<div class="py-3">
    <div class="container-fluid">
        <div class="add-bank text-end">
            <a role="button" (click)="addBankAccount()" class="btn btn-primary d-inline-flex align-items-center my-3">
                <span class="d-inline-block me-2">
                    Add Bank
                </span>
                <i class="flaticon-plus fs-6 lh-1"></i>
            </a>
        </div>
        <div *ngIf="myBankAccounts$ | async as myBankAccounts">
            @if (myBankAccounts.data.bank_accounts.length > 0) {
            <div class="row">
                <div class="col-lg-4" *ngFor="let bankAccount of myBankAccounts.data.bank_accounts">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h6 class="card-title">
                                    <span class="fw-semibold">
                                        Bank Name:
                                    </span>
                                    {{bankAccount.bank_name.name}}
                                </h6>
                                <div class="d-flex">
                                    <a role="button" type="button" class="me-3 text-danger"
                                        (click)="confirmDelete(bankAccount.id)">
                                        <i class="flaticon-delete fs-6 lh-1"></i>
                                    </a>
                                    <a role="button" type="button" class="text-muted"
                                        (click)="editBankAccount(bankAccount)">
                                        <i class="flaticon-edit fs-6 lh-1"></i>
                                    </a>
                                </div>
                            </div>
                            <p class="card-text">
                                <span class="fw-semibold">Account Number:</span> {{bankAccount.account_number}}
                            </p>
                            <p class="card-text">
                                <span class="fw-semibold">Account Name:</span> {{bankAccount.beneficiary_name}}
                            </p>

                            <p class="card-text fw-semibold">
                                Make Default Account:
                                <input type="checkbox" class="form-check-input ms-2"
                                    [checked]="bankAccount.default_account" (change)="updateDefault(bankAccount)">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            }
            @else {
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center">
                        <p class="text-muted">No bank accounts found.</p>
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
</div>