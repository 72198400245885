import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
import { add } from 'ngx-bootstrap/chronos';
import { CompanyService } from '../../../services/company.service';
import { ValidateFormService } from '../../../services/validate-form.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss'],
})
export class CompanyEditComponent {
  changeCompanyInfo: FormGroup = {} as FormGroup;
  currentUser: any;
  x = 0;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private companyService: CompanyService,
    private validateFormService: ValidateFormService,
    private toastr: ToastrService
  ) {}
  ngOnInit(): void {
    console.log('this.x', this.x);
    this.x = this.x + 5;
    console.log('this.x', this.x);
    this.changeCompanyInfo = this.fb.group({
      _method: ['PUT'],
      logo: ['', Validators.required],
      banner_image: [''],
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      address: ['', Validators.required],
      // open_at: ['', Validators.required],
      // closed_at: ['', Validators.required],
      // seo_description: ['', Validators.required],
      // seo_title: ['', Validators.required],
    });
    console.log('object', this.route.snapshot.data['title']);
    this.currentUser = this.authService.currentUserSubject.value;
    this.changeCompanyInfo.patchValue(this.currentUser.vendor);
    this.changeCompanyInfo.patchValue({
      name: this.currentUser.vendor?.shop_name,
    });
  }
  onFileChange(event: any, index: any, formControl: any): void {
    console.log('event', event);
    this.changeCompanyInfo.get(formControl)?.patchValue(event.target.files[0]);
    console.log('index', index);
    if (index) {
      const doc = document.getElementById('edit_profile');
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        let img = (doc?.getElementsByTagName('img') as any)[index];
        img.src = _event.target?.result;
      };
    }
  }
  updatecompany(): any {
    // return console.log('this.changeCompanyInfo', this.changeCompanyInfo.value);
    if (!this.changeCompanyInfo.valid) {
      console.log('this.changeCompanyInfo', this.changeCompanyInfo.value);
      return this.validateFormService.validateAllFormFields(
        this.changeCompanyInfo
      );
    }
    const formData = new FormData();
    for (const key in this.changeCompanyInfo.value) {
      formData.append(key, this.changeCompanyInfo.value[key]);
    }
    // return console.log('formData', formData);
    this.companyService.updateCompanyProfile(formData).subscribe({
      next: (data: any) => {
        this.toastr.success('Company Profile Updated Successfully');
        console.log('data', data);
      },
      error: (error) => {
        this.toastr.error('There was an error!');
        console.error('There was an error!', error);
      },
    });
  }
}
