<div class="p-3">
    <div class="container-fluid">
        <div class="text-end my-3">
            <a type="button" (click)="bsModalRef.hide()">
                <i class="flaticon-close-2"></i>
            </a>
        </div>
        <div class="mb-3 d-flex justify-content-between align-items-center">
            <div>
                <h3>
                    Pending Products
                </h3>
            </div>
            <div class="d-flex">
                <button class="btn btn-outline-danger me-3" (click)="clearCart()">
                    Clear Cart
                </button>
                <button class="btn btn-primary" (click)="confirmProducts('')">
                    Confirm All
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-6" *ngFor="let product of productService.selectedProducts ">
                <div class="product-card">
                    <!-- <div class="card">
                        <div class="card-body">
                            <div class="img-container product-img">
                                <img [src]="product.thumb_image | customImage" alt="">
                                <div class="view-icon">
                                    <i class="flaticon-view"></i>
                                </div>
                            </div>
                            <div class="product-info mt-3 d-flex justify-content-between">
                                <div>
                                    <p class="product-name">{{product.seo_title}}</p>
                                    <p class="short-desc">
                                        {{product.name}}
                                    </p>
                                </div>
                                <div>
                                    <span *ngIf="product.percentage" class="offer me-2 ">
                                        {{product.percentage}}%
                                    </span>
                                    <span [ngClass]="{'text-decoration-line-through': product.percentage}">
                                        {{product.price | currency : 'KD' : 'symbol'}}
                                    </span>
                                    <span>
                                        {{product.offer_price | currency : 'KD' : 'symbol'}}
                                    </span>
                                </div>
                            </div>
                            <div class="mt-3 d-flex gap-2">
                                <button class="btn btn-primary flex-fill" (click)="confirmProducts([product.id])">
                                    confirm
                                </button>
                                <button id="removeFromCartBtn" class="btn btn-outline-danger"
                                    (click)="removeProductFromCart(product)"
                                    *ngIf="productService.selectedProductsIds.includes(product.id)">
                                    <i class="flaticon-delete"></i>
                                </button>
                            </div>
                        </div>
                    </div> -->
                    <div class="card">
                        <div class="card-body">
                            <div class="img-container product-img">
                                <img [src]="product.thumb_image | customImage" alt="">
                                <a [href]="productURL + product.slug" target="_blank">
                                    <div class="view-icon">
                                        <i class="flaticon-view"></i>
                                    </div>
                                </a>
                            </div>
                            <div class="product-info mt-3 d-flex justify-content-between">
                                <div>
                                    <p class="product-name" [tooltip]="product.name">{{product.name}}</p>
                                    <p class="short-desc" [tooltip]="product.short_name">
                                        {{product.short_name}}
                                    </p>
                                </div>
                                <div>
                                    <span *ngIf="product.percentage" class="offer me-2 ">
                                        {{product.percentage}}%
                                    </span>
                                    <span>
                                        {{product.price | currency : 'KD' : 'symbol'}}
                                    </span>
                                    <p class="fw-bold">
                                        {{product.offer_price | currency: 'KD' : 'symbol'}}
                                    </p>
                                </div>
                            </div>
                            <div class="mt-3 d-flex gap-2">
                                <button class="btn btn-primary flex-fill" (click)="confirmProducts([product.id])">
                                    confirm
                                </button>
                                <button id="removeFromCartBtn" class="btn btn-outline-danger"
                                    (click)="removeProductFromCart(product)"
                                    *ngIf="productService.selectedProductsIds.includes(product.id)">
                                    <i class="flaticon-delete"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>