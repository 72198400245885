import { Component, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexFill,
  ApexStroke,
} from 'ng-apexcharts';
import { CelebData } from '../../types/celebrity-dashboard.types';
import { CelebrityService } from '../../services/celebrity.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  title: ApexTitleSubtitle;
  stroke: ApexStroke;
};

@Component({
  selector: 'lib-celebrity-details',
  templateUrl: './celebrity-details.component.html',
  styleUrl: './celebrity-details.component.scss',
})
export class CelebrityDetailsComponent {
  dashboard: CelebData = {} as CelebData;
  products: any[] = [];
  @ViewChild('chart') chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  earnings: string = 'Profit';
  celebrity: any;
  constructor(private celebrityService: CelebrityService) {}

  ngOnInit() {
    this.getCelebrityDashboard();
    this.getProfile();
    this.getActiveProducts();
  }

  getCelebrityDashboard() {
    this.celebrityService.getCelebrityDashboard().subscribe({
      next: (res) => {
        this.dashboard = res.data;
        this.initChart(this.dashboard.profitByMonth);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  initChart(data: number[]) {
    this.chartOptions = {
      series: [
        {
          name: this.earnings,
          data: data,
        },
      ],
      chart: {
        height: 250,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          columnWidth: '45%',
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        // formatter: function (val: any) {
        //   return val + '%';
        // },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },

      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        position: 'bottom',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: true,
          offsetY: -35,
        },
      },
      stroke: {
        width: 2,
        colors: ['transparent'],
      },
      fill: {
        colors: ['#333333'],
        opacity: 1,
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter: function (val: any) {
            return val;
          },
        },
      },
    };
  }

  onEarningsClicked(event: string, data: number[]) {
    this.earnings = event;
    this.initChart(data);
  }

  getProfile() {
    this.celebrityService.getCelebrityProfile().subscribe({
      next: (res) => {
        this.celebrity = res.data;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
  getActiveProducts() {
    this.celebrityService.getActiveProduct().subscribe({
      next: (res) => {
        this.products = res.data.data;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}
