import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CELEB_ROUTE } from '../../consts/celebrityRoutes';
import { COMPANY_ROUTE } from '../../consts/companyRoutes';

export interface routes {
  icon: string;
  title: string;
  route: string;
}
@Component({
  selector: 'lib-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  routes: routes[] = [];
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    if (this.route.snapshot.data['title'] == 'affiliate') {
      this.routes = CELEB_ROUTE;
    } else if (this.route.snapshot.data['title'] == 'company') {
      this.routes = COMPANY_ROUTE;
    }
  }
}
