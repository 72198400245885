import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../environments/src';

@Pipe({ name: 'customImage' })
export class CustomImagePipe implements PipeTransform {
  transform(value: string) {
    if (!value) {
      return 'https://dummyimage.com/600x400/f1f1f1/f1f1f1';
    } else {
      return environment.IMG_URL + value;
    }
  }
}
