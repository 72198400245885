import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/src';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http_client: HttpClient) {}
  public selectedProducts: any[] = [];
  public selectedProductsIds: string[] = [];

  getProducts(perPage: any, page: any) {
    const URL = `${environment.BASE_URL}affiliate/all_products`;
    return this.http_client.get(URL, { params: { per_page: perPage, page } });
  }
  sendProducts(data: any) {
    const URL = `${environment.BASE_URL}affiliate/adding_product_for_affiliate`;
    return this.http_client.post(URL, data);
  }
}
