<div class="py-3" *ngIf="banks$ | async as banks">
    <div class="container-fluid">
        <div>
            <div class="d-flex justify-content-between align-items-center">
                <h5 class="card-title my-3"> {{type.charAt(0).toUpperCase() + type.slice(1)}} Bank Account</h5>
                <button class="btn" (click)="bsModalRef.hide()">
                    <i class="flaticon-close-3 fs-6 lh-1"></i>
                </button>
            </div>
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="BankAccountForm" (submit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group mb-3">
                                    <label class="form-label" for="swift-code">Swift</label>
                                    <input type="text" class="form-control" id="swift-code" placeholder="Swift"
                                        formControlName="swift_code">
                                    <div class="form-control-feedback"
                                        *ngIf="((BankAccountForm.get('swift_code')?.touched) && (BankAccountForm.get('swift_code')?.invalid))">
                                        <small *ngIf="BankAccountForm.get('swift_code')?.errors?.['required']"
                                            class="text-danger">
                                            This field is requird
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group mb-3">
                                    <label class="form-label" for="iban">IBAN</label>
                                    <input type="text" class="form-control" id="iban" placeholder="Enter IBAN"
                                        formControlName="iban">
                                    <div class="form-control-feedback"
                                        *ngIf="((BankAccountForm.get('iban')?.touched) && (BankAccountForm.get('iban')?.invalid))">
                                        <small *ngIf="BankAccountForm.get('iban')?.errors?.['required']"
                                            class="text-danger">
                                            This field is requird
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group mb-3">
                                    <label class="form-label" for="account-number">Account Number</label>
                                    <input type="text" class="form-control" id="account-number"
                                        placeholder="Account Number" formControlName="account_number">
                                    <div class="form-control-feedback"
                                        *ngIf="((BankAccountForm.get('account_number')?.touched) && (BankAccountForm.get('account_number')?.invalid))">
                                        <small *ngIf="BankAccountForm.get('account_number')?.errors?.['required']"
                                            class="text-danger">
                                            This field is requird
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group mb-3">
                                    <label class="form-label" for="bank-name">Bank Name</label>
                                    <ng-select id="bank-name" [multiple]="false" formControlName="bank_id">
                                        <ng-option *ngFor="let bank of banks.data" [value]="bank.id">
                                            {{ bank.name }}
                                        </ng-option>
                                    </ng-select>
                                    <div class="form-control-feedback"
                                        *ngIf="((BankAccountForm.get('bank_id')?.touched) && (BankAccountForm.get('bank_id')?.invalid))">
                                        <small *ngIf="BankAccountForm.get('bank_id')?.errors?.['required']"
                                            class="text-danger">
                                            This field is requird
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group mb-3">
                                    <label class="form-label" for="bank-branch">Bank Branch</label>
                                    <input type="text" class="form-control" id="bank-branch" placeholder="Bank Branch"
                                        formControlName="branch_name">
                                    <div class="form-control-feedback"
                                        *ngIf="((BankAccountForm.get('branch_name')?.touched) && (BankAccountForm.get('branch_name')?.invalid))">
                                        <small *ngIf="BankAccountForm.get('branch_name')?.errors?.['required']"
                                            class="text-danger">
                                            This field is requird
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group mb-3">
                                    <label class="form-label" for="beneficiary-name">Beneficiary name</label>
                                    <input type="text" class="form-control" id="beneficiary-name"
                                        placeholder="Beneficiary name" formControlName="beneficiary_name">
                                    <div class="form-control-feedback"
                                        *ngIf="((BankAccountForm.get('beneficiary_name')?.touched) && (BankAccountForm.get('beneficiary_name')?.invalid))">
                                        <small *ngIf="BankAccountForm.get('beneficiary_name')?.errors?.['required']"
                                            class="text-danger">
                                            This field is requird
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <input type="checkbox" class="form-check-input" id="bank-address"
                                    formControlName="default_account">
                                <label class="form-label ms-2" for="bank-address">Default Bank Address</label>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary">Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>

</div>