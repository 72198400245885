import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/src';

@Injectable({
  providedIn: 'root',
})
export class BrandsService {
  constructor(private http_client: HttpClient) {}
  getBrands(): Observable<any> {
    const URL = `${environment.BASE_URL}seller/brands`;
    return this.http_client.get<any>(URL);
  }
  getBrandById(id: string): Observable<any> {
    const URL = `${environment.BASE_URL}seller/brands/${id}`;
    return this.http_client.get<any>(URL);
  }
  createBrand(data: any) {
    const URL = `${environment.BASE_URL}seller/brands`;
    return this.http_client.post(URL, data);
  }
  updateBrand(id: string, data: any) {
    const URL = `${environment.BASE_URL}brands/${id}`;
    return this.http_client.put<any>(URL, data);
  }
  deleteBrand(id: string) {
    const URL = `${environment.BASE_URL}seller/brands/${id}`;
    return this.http_client.delete(URL);
  }
  upadteBrandStatus(id: string, status: boolean) {
    const URL = `${environment.BASE_URL}seller/brands-status/${id}`;
    return this.http_client.put(URL, { status });
  }
  getBrandCategories() {
    const URL = `${environment.BASE_URL}seller/categories`;
    return this.http_client.get(URL);
  }
}
