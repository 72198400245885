<div class="py-3">
    <div class="container-fluid" *ngIf="profile">
        <div class="row mb-3 justify-content-between align-items-center">
            <!-- <div class="col-lg-4">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/company"> Companies List </a></li>
                        <li class="breadcrumb-item active" aria-current="page"> Company Details</li>
                    </ol>
                </nav>
            </div> -->

            <div class="d-flex justify-content-end ">
                <a class="btn btn-primary p-2 px-3 d-flex justify-content-center align-items-center me-2"
                    routerLink="/company/profile">
                    <span class=" me-3"> Edit Profile </span>
                    <i class="flaticon-edit lh-1"></i>
                </a>
            </div>

        </div>
        <div class="company-info mb-4 d-flex align-items-center">
            <div class="company-avatar me-3">
                <img class="object-fit-cover w-100" [src]="profile.seller?.company_profile | customImage" alt="">
            </div>
            <div class="company-name d-inline">
                <h4 class="d-inline">
                    {{profile.seller?.shop_name}}
                </h4>
            </div>
        </div>
        <div class="action-buttons mb-4">
            <div class="row">
                <div class="col-lg-7">
                    <div class="row">
                        <div class="col-3">
                            <button class="btn btn-primary w-100 d-flex justify-content-center align-items-center">
                                <span class="me-2"> Reviews </span>
                                <i class="flaticon-review lh-1"></i>
                            </button>
                        </div>
                        <div class="col-3">
                            <button class="btn btn-primary w-100 d-flex justify-content-center align-items-center">
                                <span class="me-2"> Send Emails </span>
                                <i class="flaticon-mail lh-1"></i>
                            </button>
                        </div>
                        <div class="col-3">
                            <button class="btn btn-primary w-100 d-flex justify-content-center align-items-center">
                                <span class="me-2"> Email log </span>
                                <i class="flaticon-file-1 lh-1"></i>
                            </button>
                        </div>
                        <div class="col-3">
                            <button class="btn btn-primary w-100 d-flex justify-content-center align-items-center">
                                <span class="me-2"> Reports </span>
                                <i class="flaticon-file-1 lh-1"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="card rounded-4 status-card">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <div class="number">
                                    <h3 class="text-truncate" [tooltip]="profile.totalSoldProduct">
                                        {{ profile.totalSoldProduct }}
                                    </h3>
                                </div>
                                <div class="status">
                                    <h5>
                                        Total sales items
                                    </h5>
                                </div>
                            </div>
                            <div class="icon bg-danger text-danger text-center p-1 mw-50 rounded-2">
                                <i class="flaticon-price-tag"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="card rounded-4 status-card">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <div class="number">
                                    <h3 class="text-truncate">
                                        {{ profile.seller?.products?.length }}
                                    </h3>
                                </div>
                                <div class="status">
                                    <h5>
                                        Total Products
                                    </h5>
                                </div>
                            </div>
                            <div class="icon bg-success-light text-center p-1 mw-50 rounded-2">
                                <i class="flaticon-box"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="card rounded-4 status-card">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <div class="number">
                                    <h3 class="text-truncate">
                                        {{ profile.totalOrder }}
                                    </h3>
                                </div>
                                <div class="status">
                                    <h5>
                                        Total orders
                                    </h5>
                                </div>
                            </div>
                            <div class="icon bg-success-light text-center p-1 mw-50 rounded-2">
                                <i class="flaticon-shopping-cart"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <a routerLink="/brands">
                    <div class="card rounded-4 status-card">
                        <div class="card-body">
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <div class="number">
                                        <h3 class="text-truncate">
                                            {{brands.length}}
                                        </h3>
                                    </div>
                                    <div class="status">
                                        <h5>
                                            Total brands
                                        </h5>
                                    </div>
                                </div>
                                <div class="icon bg-yellow text-center p-1 mw-50 rounded-2">
                                    <i class="flaticon-top-brand"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="card rounded-4 status-card">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <div class="number">
                                    <h3 class="text-truncate" [tooltip]="profile.totalWithdraw">
                                        {{ profile.totalWithdraw }}
                                    </h3>
                                </div>
                                <div class="status">
                                    <h5>
                                        Total withdraw
                                    </h5>
                                </div>
                            </div>
                            <div class="icon bg-success text-success text-center p-1 mw-50 rounded-2">
                                <i class="flaticon-withdraw"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="card rounded-4 status-card">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <div class="number">
                                    <h3 class="text-truncate" [tooltip]="profile.totalAmount">
                                        {{ profile.totalAmount }}
                                    </h3>
                                </div>
                                <div class="status">
                                    <h5>
                                        Total amount
                                    </h5>
                                </div>
                            </div>
                            <div class="icon bg-blue text-center p-1 mw-50 rounded-2">
                                <i class="flaticon-wallet"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="summery">
            <div class="row">
                <div class="col-lg-6">
                    <div class="card mb-3 mb-lg-4 bg-white rounded-4">
                        <div class="card-body">
                            <div class="card-header mb-3 d-flex justify-content-between align-items-center">
                                <h5>Sales Summery</h5>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-xl-4">
                                    <div class="small-card mb-lg-3 mb-2 today p-3 rounded-4">
                                        <div class="icon mb-2 rounded-5">
                                            <i class="flaticon-graph-analysis"></i>
                                        </div>
                                        <p class="KD"> {{ company.salesSummary.todaySales }} KD</p>
                                        <p class="info">Today's Sales</p>
                                        <small class="ratio"> +8% from yesterday</small>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4">
                                    <div class="small-card mb-lg-3 mb-2 month p-3 rounded-4">
                                        <div class="icon mb-2 rounded-5">
                                            <i class="flaticon-graph-analysis"></i>
                                        </div>
                                        <p class="KD"> {{ company.data.salesSummary.monthlySales }} KD</p>
                                        <p class="info">Last 30 Days Sales</p>
                                        <small class="ratio"> +8% from yesterday</small>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4">
                                    <div class="small-card mb-lg-3 mb-2 year p-3 rounded-4">
                                        <div class="icon mb-2 rounded-5">
                                            <i class="flaticon-graph-analysis"></i>
                                        </div>
                                        <p class="KD">{{ company.data.salesSummary.yearlySales }} KD</p>
                                        <p class="info">Yearly Sales</p>
                                        <small class="ratio"> +1,2% from yesterday</small>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="card mb-3 mb-lg-4 bg-white rounded-4">
                        <div class="card-body">
                            <div class="card-header mb-3 d-flex justify-content-between align-items-center">
                                <h5>Profit Summery</h5>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-xl-4">
                                    <div class="small-card mb-lg-3 mb-2 today p-3 rounded-4">
                                        <div class="icon mb-2 rounded-5">
                                            <i class="flaticon-graph-analysis"></i>
                                        </div>
                                        <p class="KD">{{ company.data.todayEarning }} KD</p>
                                        <p class="info">Today's profit</p>
                                        <small class="ratio"> +8% from yesterday</small>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4">
                                    <div class="small-card mb-lg-3 mb-2 month p-3 rounded-4">
                                        <div class="icon mb-2 rounded-5">
                                            <i class="flaticon-graph-analysis"></i>
                                        </div>
                                        <p class="KD">{{ company.data.thisMonthEarning }} KD</p>
                                        <p class="info">Last 30 Days Sales</p>
                                        <small class="ratio"> +8% from yesterday</small>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4">
                                    <div class="small-card mb-xl-0 mb-2 year p-3 rounded-4">
                                        <div class="icon mb-2 rounded-5">
                                            <i class="flaticon-graph-analysis"></i>
                                        </div>
                                        <p class="KD">{{ company.data.thisYearEarning }} KD</p>
                                        <p class="info">Yearly profit</p>
                                        <small class="ratio"> +1,2% from yesterday</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>