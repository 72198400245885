<div class="add-products py-3">
    <div class="container-fluid">
        <div class="d-flex justify-content-end">
            <div class="cart-icon">
                <button class="btn btn-primary" (click)="openConfirmationModal()">
                    <i class="flaticon-shopping-bag"></i>
                </button>
                <span class="cart-count">
                    {{ProductService.selectedProducts.length}}
                </span>
            </div>
        </div>
        <!-- <div class="table-filter my-4">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-lg-9 col-md-6">
                        <div class="form-group mb-3 mb-lg-0">
                            <input type="text" name="search" class="form-control rounded-2"
                                placeholder="Search by name or order ID" formControlName="search">
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="row">
                            <div class="col-6">
                                <button
                                    class="btn bg-white filter-btn w-100 mb-3 mb-lg-0 d-flex align-items-center justify-content-center">
                                    <i class="flaticon-filter-1"></i>
                                    <span>
                                        filter
                                    </span>
                                </button>
                            </div>
                            <div class="col-6">
                                <button
                                    class="btn bg-white filter-btn w-100 mb-3 mb-lg-0 d-flex align-items-center justify-content-center ">
                                    <i class="flaticon-down"></i>
                                    <span>
                                        sort
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group mt-5 mb-2">
                    <small class="text-secondary me-2"> show </small>
                    <select (ngModelChange)="updatePerPage()" name="show" class="form-select d-inline-block w-10"
                        formControlName="per_page">
                        <option [value]="10">10</option>
                        <option [value]="20">20</option>
                        <option [value]="30">30</option>
                        <option [value]="40">40</option>
                    </select>
                    <small class="text-secondary ms-2">Entries</small>
                </div>
            </form>
        </div> -->
        <div class="row">
            <div class="col-lg-3 col-6" *ngFor="let product of products">
                <div class="product-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="img-container product-img">
                                <img [src]="product.thumb_image | customImage" alt="">
                                <a [href]="productURL + product.slug" target="_blank">
                                    <div class="view-icon">
                                        <i class="flaticon-view"></i>
                                    </div>
                                </a>
                            </div>
                            <div class="product-info mt-3 d-flex justify-content-between">
                                <div>
                                    <p class="product-name" [tooltip]="product.name">{{product.name}}</p>
                                    <p class="short-desc" [tooltip]="product.short_name">
                                        {{product.short_name}}
                                    </p>
                                </div>
                                <div>
                                    <span *ngIf="product.percentage" class="offer me-2 ">
                                        {{product.percentage}}%
                                    </span>
                                    <span>
                                        {{product.price | currency : 'KD' : 'symbol'}}
                                    </span>
                                    <p class="fw-bold">
                                        {{product.offer_price | currency: 'KD' : 'symbol'}}
                                    </p>
                                </div>
                            </div>
                            <button id="addToTheCartBtn" class="btn btn-primary w-100 mt-3"
                                (click)="addProductToCart(product)"
                                *ngIf="!ProductService.selectedProductsIds.includes(product.id)">
                                Add
                            </button>
                            <button id="removeFromCartBtn" class="btn btn-secondary w-100 mt-3"
                                (click)="removeProductFromCart(product)"
                                *ngIf="ProductService.selectedProductsIds.includes(product.id)">
                                undo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pagination [totalItems]="totalItems" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"
            [itemsPerPage]="form.get('per_page')?.value ?? 10">
        </pagination>
    </div>
</div>