<nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
        <a class="navbar-brand order-2 order-md-1" href="#">
            <img src="./assets/images/logo-dark.svg" alt="">
        </a>
        <!-- <div class="navbar-collapse mt-3 mt-lg-0" id="navbarSupportedContent"> -->
        <!-- <ul class="navbar-nav me-lg-auto mb-2 mb-lg-0 order-3 order-2">
            <form class="mw-3" action="">
                <li class="nav-item">
                    <div class="form-group">
                        <input type="text" class="form-control border-0" placeholder="Search Service...">
                    </div>
                </li>
            </form>
        </ul> -->
        <div class="header-actions d-flex justify-content-between align-items-center order-2 order-sm-3 ">
            <!-- <a class="mode  me-1 me-lg-3 rounded-5 bg-light text-secondary text-center">
                <i class="flaticon-night-mode"></i>
            </a> -->
            <div class=" me-1 me-lg-3 p-2 rounded-5 bg-light ">
                <span class="text-secondary">{{ today | date:'medium' }}</span>
            </div>
            <div class="img-container position-relative" dropdown [insideClick]="false">
                <a role="button" dropdownToggle class="dropdown-toggle" aria-controls="dropdown-basic">
                    <img *ngIf="activeRoute == 'affiliate'" [src]="currentUser.affiliate?.logo | customImage" alt="">
                    <img *ngIf="activeRoute == 'seller'" [src]="currentUser.vendor?.logo | customImage" alt="">
                </a>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li role="menuitem">
                        <a class="dropdown-item fw-medium" *ngIf="activeRoute === 'affiliate'">Welcome,
                            {{currentUser.affiliate?.name}}</a>
                        <a class="dropdown-item fw-medium" *ngIf="activeRoute === 'seller'">Welcome,
                            {{currentUser.user?.name}}</a>
                    </li>
                    <li class="divider dropdown-divider"></li>
                    <li role="menuitem"><a class="dropdown-item"
                            [routerLink]="'/'+ (activeRoute === 'seller' ? 'company' : 'celebrities' ) + '/profile' ">Profile</a>
                    </li>
                    <li role="menuitem"><a class="dropdown-item" routerLink="/transactions">Transactions</a></li>
                    <li class="divider dropdown-divider"></li>
                    <li role="menuitem"><a role="button" class="dropdown-item text-danger" (click)="logout()">Logout</a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- </div> -->
    </div>
</nav>