export const CELEB_ROUTE = [
  {
    route: '/celebrities',
    icon: 'flaticon-celebrity',
    title: 'Celebrity',
  },
  {
    route: '/transactions',
    title: 'Transactions',
    icon: 'flaticon-celebrity',
  },
];
