import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullLayoutComponent } from './full-layout/full-layout.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContentLayoutComponent } from './content-layout/content-layout.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { HeaderComponent } from '../components/header/header.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [
    ContentLayoutComponent,
    FullLayoutComponent,
    SidebarComponent,
    HeaderComponent,
  ],
  exports: [
    ContentLayoutComponent,
    FullLayoutComponent,
    SidebarComponent,
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    PipesModule,
  ],
})
export class LayoutModule {}
