import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/src';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CashoutService {
  constructor(private http_client: HttpClient) {}
  getCashouts(type: string, brand_id?: string): Observable<any> {
    const URL = `${environment.BASE_URL}${type}/my-cashouts${brand_id ? `/${brand_id}` : ''}`;
    return this.http_client.get<any>(URL);
  }
  requestCashout(data: any, type: string) {
    const URL = `${environment.BASE_URL}${type}/my-cashouts`;
    return this.http_client.post(URL, data);
  }

  deleteCashout(id: string, type: string) {
    const URL = `${environment.BASE_URL}${type}/delete_cashout/${id}`;
    return this.http_client.post(URL, {});
  }
  updateCashout(id: string, data: any, type: string) {
    const URL = `${environment.BASE_URL}${type}/my-cashouts/${id}`;
    return this.http_client.put<any>(URL, data);
  }
}
