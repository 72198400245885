import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipesModule } from '../../pipes/pipes.module';
import { CelebritiesRoutingModule } from './celebrities-routing.module';
import { CelebrityDetailsComponent } from './celebrity-details.component';
import { CreateManagerComponent } from './create-manager/create-manager.component';
import { ProfileComponent } from './profile/profile.component';

import { ModalModule } from 'ngx-bootstrap/modal';
import { AddProductsComponent } from './add-products/add-products.component';
import { ConfirmProductsModalComponent } from './confirm-products-modal/confirm-products-modal.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProductsTableComponent } from '../../components/products-table/products-table.component';

@NgModule({
  declarations: [
    CelebrityDetailsComponent,
    ProfileComponent,
    CreateManagerComponent,
    AddProductsComponent,
    ConfirmProductsModalComponent,
  ],
  imports: [
    CommonModule,
    CelebritiesRoutingModule,
    RouterModule,
    NgSelectModule,
    PipesModule,
    BsDatepickerModule.forRoot(),
    NgApexchartsModule,
    TooltipModule.forRoot(),
    ProductsTableComponent,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    FormsModule,
  ],
})
export class CelebritiesModule {}
