import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TransactionsComponent } from './transactions.component';
import { WithdrawRequestComponent } from './withdrawals/withdraw-request/withdraw-request.component';
import { BanksComponent } from './banks/banks.component';
import { WithdrawalsComponent } from './withdrawals/withdrawals.component';

const routes: Routes = [
  {
    path: '',
    component: TransactionsComponent,
    children: [
      { path: 'banks', component: BanksComponent },
      { path: 'withdrawal', component: WithdrawalsComponent },
      { path: '', redirectTo: 'withdrawal', pathMatch: 'full' },
    ],
  },
  { path: 'request-withdraw', component: WithdrawRequestComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TransactionsRoutingModule {}
