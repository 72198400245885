<div class="p-3" id="add_brand">
    <form [formGroup]="form">
        <div class="row justify-content-center">
            <div class="col-lg-7">
                <div class="brand-img-upload mb-3">
                    <div class="preview-img">
                        <img src="./assets/images/bannerImage.png" alt="">
                    </div>
                    <input type="file" id="brand-logo" name="logo" (change)="onFileChange($event, '0','logo')">
                    <label class="upload-image" for="brand-logo">
                        <i class="flaticon-upload me-2"></i>
                        <span>Upload Image</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 mb-3">
                <div class="form-group">
                    <label for="brandName" class="mb-2">Brand Name</label>
                    <input type="text" class="form-control" id="brandName" placeholder="Enter Brand Name"
                        name="brandName" formControlName="name">
                    <div class="form-control-feedback"
                        *ngIf="((form.get('name')?.touched) && (form.get('name')?.invalid))">
                        <small *ngIf="form.get('name')?.errors?.['required']" class="text-danger">
                            This field is requird
                        </small>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mb-3">
                <div class="form-group">
                    <label for="brandSlug" class="mb-2">Brand Slug</label>
                    <input type="text" class="form-control" id="brandSlug" placeholder="Enter Brand Slug"
                        name="brandSlug" formControlName="slug">
                    <div class="form-control-feedback"
                        *ngIf="((form.get('slug')?.touched) && (form.get('slug')?.invalid))">
                        <small *ngIf="form.get('slug')?.errors?.['required']" class="text-danger">
                            This field is requird
                        </small>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mb-3">
                <div class="form-group">
                    <label for="brandCategories" class="mb-2">Brand Categories</label>
                    <ng-select name="country_id" [multiple]="true" formControlName="categories">
                        <ng-option value="" selected>Select category</ng-option>
                        <ng-option *ngFor="let Category of cats" [value]="Category.id">
                            {{ Category.name }}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="col-lg-6 mb-3">
                <div class="form-check form-switch d-inline-block">
                    <p>
                        <label class="form-check-label text-success" for="newBrandStatus">
                            Active
                        </label>
                        /
                        <label class="form-check-label text-danger" for="newBrandStatus">
                            Inactive
                        </label>
                    </p>
                    <input class="form-check-input" type="checkbox" role="switch" id="newBrandStatus"
                        formControlName="status">
                </div>
            </div>
        </div>
        <div class="form-group mt-2 text-end">
            <button class="btn btn-primary text-white" (click)="addBrand()">Add Brand</button>
        </div>
    </form>
</div>