import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth/auth.service';
import { ValidateFormService } from '../../services/validate-form.service';

@Component({
  selector: 'lib-auth',
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss',
})
export class AuthComponent {
  show: boolean = false;
  loading: boolean = false;
  type: string = 'affiliate';
  public form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
  });
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private authService: AuthService,
    private validateForm: ValidateFormService
  ) {}

  ngOnInit(): void {
    this.type = this.route.snapshot.data['title'];
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.loading = true;
      this.authService
        .login(this.form.value.email, this.form.value.password, this.type)
        .subscribe({
          next: (response: any) => {
            localStorage.setItem('currentUser', JSON.stringify(response.data));
            // notify
            this.authService.currentUserSubject.next(response.data);
            this.loading = false;
            if (this.type === 'affiliate') {
              this.router.navigate(['/celebrities/details']);
            } else if (this.type === 'seller') {
              console.log('seller');
              this.router.navigate(['/company/details']);
            }
          },
          error: (error) => {
            this.loading = false;
            console.log(error);
            if (error['error']['code'] === 401) {
              this.toastrService.error(
                'Invalid username or password. Please check your credentials and try again.'
              );
            }
          },
        });
    } else {
      this.validateForm.validateAllFormFields(this.form);
    }
  }
}
