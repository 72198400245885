import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ProductService } from '../../../services/product.service';
import { environment } from '../../../environments/src';

@Component({
  selector: 'lib-confirm-products-modal',
  templateUrl: './confirm-products-modal.component.html',
  styleUrl: './confirm-products-modal.component.scss',
})
export class ConfirmProductsModalComponent {
  constructor(
    public bsModalRef: BsModalRef,
    public productService: ProductService,
    private modalService: BsModalService
  ) {}

  productURL = environment.WEBSITE_URL + 'products/details/';

  removeProductFromCart(product: any) {
    this.productService.selectedProducts =
      this.productService.selectedProducts.filter(
        (element: any) => element.id !== product.id
      );
    this.productService.selectedProductsIds =
      this.productService.selectedProductsIds.filter(
        (element: any) => element !== product.id
      );
    if (this.productService.selectedProducts.length == 0) {
      this.bsModalRef.hide();
    }
  }
  ngOnInit() {}
  confirmProducts(confirmed_id: any) {
    // confirmed_id is an array of one product id
    let sentData: any;
    confirmed_id.length
      ? (sentData = confirmed_id)
      : (sentData = this.productService.selectedProductsIds);
    console.log(sentData);
    const formData = new FormData();
    for (let i = 0; i < sentData.length; i++) {
      const element = sentData[i];
      formData.set(`ids[${i}]`, element.toString());
    }
    this.productService.sendProducts(formData).subscribe({
      next: (res: any) => {
        this.handleConirmedProducts(confirmed_id);
        this.modalService.setDismissReason('ProductsConfirmed');
        this.bsModalRef.hide();
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }
  clearCart() {
    this.productService.selectedProducts = [];
    this.productService.selectedProductsIds = [];
    this.bsModalRef.hide();
  }

  handleConirmedProducts(confirmed_id: any) {
    if (confirmed_id.length) {
      this.productService.selectedProducts =
        this.productService.selectedProducts.filter(
          (element: any) => element.id !== confirmed_id[0]
        );
      this.productService.selectedProductsIds =
        this.productService.selectedProductsIds.filter(
          (element: any) => element !== confirmed_id[0]
        );
    } else {
      this.productService.selectedProducts = [];
      this.productService.selectedProductsIds = [];
    }
  }
}
