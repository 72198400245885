import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/src';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  //public
  public currentUser: Observable<any>;

  //private
  public currentUserSubject: BehaviorSubject<any>;
  constructor(private http_client: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(
        localStorage.getItem('currentUser')
          ? localStorage.getItem('currentUser') || '{}'
          : sessionStorage.getItem('currentUser') || '{}'
      )
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  login(email: string, password: string, type: string): Observable<any> {
    const URL = `${environment.BASE_URL}${type}/login`;
    return this.http_client.post(URL, { email, password });
  }

  logout(type: string): Observable<any> {
    const URL = `${environment.BASE_URL}${type}/logout`;
    return this.http_client.post(URL, {});
  }
  updatePassword(data: any, type: string) {
    const URL = `${environment.BASE_URL}${type}/update-my-password`;
    return this.http_client.post(URL, data);
  }
}
