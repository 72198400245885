<div class="container-fluid">
    <div class="d-flex my-3 justify-content-between align-items-center">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/company/details"> Company Details </a></li>
                <li class="breadcrumb-item"><a routerLink="/brands"> brands </a></li>
                <li class="breadcrumb-item active" aria-current="page"> Brand Details</li>
            </ol>
        </nav>
        <div class="d-flex">
            <a class="btn btn-primary me-3 p-2  d-flex justify-content-center align-items-center">
                <span class="me-3"> Edit Profile </span>
                <i class="flaticon-edit lh-1"></i>
            </a>
            <a class="btn btn-primary p-2 px-3 d-flex justify-content-center align-items-center"
                routerLink="/transactions">
                <span class="me-3"> withdraw </span>
                <i class="flaticon-withdraw lh-1"></i>
            </a>
        </div>
    </div>
    <div class="company-info mb-4">
        <div class="company-avatar">
            <img class="object-fit-cover w-100" [src]="brand?.logo | customImage" alt="">
        </div>
        <div class="company-name d-inline">
            <h4 class="d-inline">
                {{brand?.name}}
            </h4>
        </div>
    </div>
    <div class="action-buttons mb-4">
        <div class="row">
            <div class="col-lg-7">
                <div class="row">
                    <div class="col-4">
                        <button class="btn btn-primary w-100 d-flex justify-content-center align-items-center">
                            <span class="me-2"> Reviews </span>
                            <i class="flaticon-review lh-1"></i>
                        </button>
                    </div>
                    <div class="col-4">
                        <button class="btn btn-primary w-100 d-flex justify-content-center align-items-center">
                            <span class="me-2"> Send Emails </span>
                            <i class="flaticon-mail lh-1"></i>
                        </button>
                    </div>
                    <div class="col-4">
                        <button class="btn btn-primary w-100 d-flex justify-content-center align-items-center">
                            <span class="me-2"> Email log </span>
                            <i class="flaticon-file-1 lh-1"></i>
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-3 col-md-6">
            <div class="card rounded-4 status-card">
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between">
                        <div>
                            <div class="number">
                                <h3>
                                    69
                                </h3>
                            </div>
                            <div class="status">
                                <h5>
                                    Total sales items
                                </h5>
                            </div>
                        </div>
                        <div class="icon bg-danger text-danger text-center p-1 mw-50 rounded-2">
                            <i class="flaticon-price-tag"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6">
            <div class="card rounded-4 status-card">
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between">
                        <div>
                            <div class="number">
                                <h3>
                                    {{brand?.products_count}}
                                </h3>
                            </div>
                            <div class="status">
                                <h5>
                                    Total Products
                                </h5>
                            </div>
                        </div>
                        <div class="icon bg-success-light text-center p-1 mw-50 rounded-2">
                            <i class="flaticon-box"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6">
            <div class="card rounded-4 status-card">
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between">
                        <div>
                            <div class="number">
                                <h3>
                                    20
                                </h3>
                            </div>
                            <div class="status">
                                <h5>
                                    Total withdraw
                                </h5>
                            </div>
                        </div>
                        <div class="icon bg-success text-success text-center p-1 mw-50 rounded-2">
                            <i class="flaticon-withdraw"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6">
            <div class="card rounded-4 status-card">
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between">
                        <div>
                            <div class="number">
                                <h3>
                                    10
                                </h3>
                            </div>
                            <div class="status">
                                <h5>
                                    Current balance
                                </h5>
                            </div>
                        </div>
                        <div class="icon bg-blue text-center p-1 mw-50 rounded-2">
                            <i class="flaticon-wallet"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="summery">
        <div class="row">
            <div class="col-lg-6">
                <div class="card mb-3 mb-lg-4 bg-white rounded-4">
                    <div class="card-body">
                        <div class="card-header mb-3 d-flex justify-content-between align-items-center">
                            <h6>Sales Summery</h6>
                            <form action="">
                                <div class="form-group">
                                    <input type="text" placeholder="Datepicker" class="form-select" bsDatepicker>
                                </div>
                            </form>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-xl-4">
                                <div class="small-card mb-lg-3 mb-2 today p-3 rounded-4">
                                    <div class="icon mb-2 rounded-5">
                                        <i class="flaticon-graph-analysis"></i>
                                    </div>
                                    <p class="KD">25.000 KD</p>
                                    <p class="info">Today's Sales</p>
                                    <small class="ratio"> +8% from yesterday</small>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="small-card mb-lg-3 mb-2 month p-3 rounded-4">
                                    <div class="icon mb-2 rounded-5">
                                        <i class="flaticon-graph-analysis"></i>
                                    </div>
                                    <p class="KD">25.000 KD</p>
                                    <p class="info">Last 30 Days Sales</p>
                                    <small class="ratio"> +8% from yesterday</small>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="small-card mb-lg-3 mb-2 year p-3 rounded-4">
                                    <div class="icon mb-2 rounded-5">
                                        <i class="flaticon-graph-analysis"></i>
                                    </div>
                                    <p class="KD">25.000 KD</p>
                                    <p class="info">Today's Sales</p>
                                    <small class="ratio"> +1,2% from yesterday</small>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card mb-3 mb-lg-4 bg-white rounded-4">
                    <div class="card-body">
                        <div class="card-header mb-3 d-flex justify-content-between align-items-center">
                            <h6>Profit Summery</h6>
                            <div class="form-group">
                                <form action="">
                                    <input type="text" placeholder="Datepicker" class="form-select" bsDatepicker>
                                </form>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6 col-xl-4">
                                <div class="small-card mb-lg-3 mb-2 today p-3 rounded-4">
                                    <div class="icon mb-2 rounded-5">
                                        <i class="flaticon-graph-analysis"></i>
                                    </div>
                                    <p class="KD">25.000 KD</p>
                                    <p class="info">Today's profit</p>
                                    <small class="ratio"> +8% from yesterday</small>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="small-card mb-lg-3 mb-2 month p-3 rounded-4">
                                    <div class="icon mb-2 rounded-5">
                                        <i class="flaticon-graph-analysis"></i>
                                    </div>
                                    <p class="KD">25.000 KD</p>
                                    <p class="info">Last 30 Days Sales</p>
                                    <small class="ratio"> +8% from yesterday</small>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="small-card mb-xl-0 mb-2 year p-3 rounded-4">
                                    <div class="icon mb-2 rounded-5">
                                        <i class="flaticon-graph-analysis"></i>
                                    </div>
                                    <p class="KD">25.000 KD</p>
                                    <p class="info">Today's profit</p>
                                    <small class="ratio"> +1,2% from yesterday</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="table-responsive custom-table product-table mb-3">
        <table class="w-100 border-2 border-secondary">
            <thead>
                <tr>
                    <th class="text-start" colspan="10">
                        brand Products
                    </th>
                    <th class="text-end" colspan="2">
                        <button class="btn btn-primary rounded-3 p-2">
                            <span class="me-2">
                                Add new
                            </span>
                            <i class="flaticon-plus fs-13"></i>
                        </button>
                    </th>
                </tr>
                <tr>
                    <th><input type="checkbox" class="form-check d-inline-block"></th>
                    <th> SN </th>
                    <th> Name </th>
                    <th> Product</th>
                    <th> Added by</th>
                    <th> Status </th>
                    <th> Price </th>
                    <th> Percentage</th>
                    <th> Profit </th>
                    <th> Brand </th>
                    <th> status </th>
                    <th> Action</th>
                </tr>
            </thead>
            <tbody>
                @if(brand?.products.length > 0) {
                <tr *ngFor="let product of brand?.products">
                    <td><input type="checkbox" class="form-check d-inline-block"></td>
                    <td>
                        {{product.id}}
                    </td>
                    <td>
                        <p>
                            {{product.name}}
                        </p>
                    </td>
                    <td>
                        <div class="product-img d-inline-block">
                            <img [src]="product.thumb_image | customImage" alt="">
                            <a role="button" class="btn btn-primary view-btn" [href]="productURL + product.slug"
                                target="_blank">
                                View
                            </a>
                        </div>
                    </td>
                    <td>
                        {{product.added}}
                    </td>
                    <td>
                        <div class="bg-success text-success p-1 rounded-3">
                            <p>
                                Aprroved
                            </p>
                        </div>
                    </td>
                    <td>
                        {{product.price | currency : 'KD' : 'symbol' : '1.2-2'}}
                    </td>
                    <td>
                        10%
                    </td>
                    <td>
                        MAC
                    </td>
                    <td>
                        20KD
                    </td>
                    <td>
                        <div class="form-check form-switch d-inline-block">
                            <label class="form-check-label text-success" for="flexSwitchCheckChecked">
                                Active
                            </label>
                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                                checked>
                        </div>
                    </td>
                    <td>
                        <button class="btn btn-light lh-1">
                            <i class="flaticon-delete fs-25 "></i>
                        </button>
                    </td>
                </tr>
                }
                @else {
                <tr>
                    <td colspan="12">
                        <p>No products Found</p>
                    </td>
                </tr>
                }
            </tbody>
        </table>
    </div>
    <div class="footer my-3 d-flex justify-content-between">
        <div>
            <small class="text-secondary  fs-13">Showing 1 to 7 of 100 entries</small>
        </div>
        <!-- pagination goes here -->
        <div>
            <!-- <pagination [totalItems]="total" [maxSize]="12" [(ngModel)]="page" (pageChanged)="loadMore($event)">
            </pagination> -->
        </div>
    </div>
</div>