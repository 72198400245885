import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../pipes/pipes.module';
import { BrandsService } from '../../services/brands.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'lib-brand-card',
  standalone: true,
  imports: [CommonModule, RouterModule, PipesModule],
  templateUrl: './brand-card.component.html',
  styleUrl: './brand-card.component.scss',
})
export class BrandCardComponent {
  constructor(
    private brandsService: BrandsService,
    private toster: ToastrService
  ) {}
  @Input() brand: any;
  @Output() statusUpdated = new EventEmitter<string>();
  changeStatus(id: any) {
    this.brandsService.upadteBrandStatus(id, !this.brand.status).subscribe({
      next: (data) => {
        this.toster.success('Brand status updated');
        console.log('Brand status updated', data);
        this.statusUpdated.emit('status updated');
      },
      error: (error) => {
        console.log('Error while updating brand status', error);
      },
    });
  }
  confirmDelete(brand_Id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this brand!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    }).then((result) => {
      if (result.value) {
        this.deleteBrand(brand_Id);
      }
    });
  }
  deleteBrand(brandId: string) {
    this.brandsService.deleteBrand(brandId).subscribe({
      next: (data) => {
        this.toster.success('Brand deleted successfully');
        console.log('Brand deleted successfully', data);
        this.statusUpdated.emit('brand deleted');
      },
      error: (error) => {
        console.log('Error while deleting brand', error);
      },
    });
  }
}
