<div class="signPages d-flex">
    <div class="nonFormSection d-none d-lg-block position-relative loginbackground">
        <div class="overlay position-absolute">
            <h4 class="mb-5">
                your one stop shop for beauty
            </h4>
            <!-- <p>
                "Explore our Real Estate Consultancy platform. Login or register for personalized advice and exclusive
                insights tailored to your goals."
            </p> -->
        </div>
    </div>
    <div class="formSection w-50 w-md-100 d-flex align-items-center justify-content-center flex-column">
        <div class="mb-4">
            <img src="../../assets/images/logo-dark.svg" alt="logo" class="logo">
        </div>
        <form [formGroup]="form" (submit)="onSubmit()">
            <h3>
                Welcome to your {{type == 'affiliate' ? 'boutique' : 'company'}}.<br> Glad to see you, Again!
            </h3>
            <div class="input-field mt-4">
                <input placeholder="Enter your email" class="form-control mh-45-px" id="email" type="email"
                    formControlName="email">
                <div class="form-control-feedback"
                    *ngIf="((form.get('email')?.touched) && (form.get('email')?.invalid))">
                    <small *ngIf="form.get('email')?.errors?.['required']" class="text-danger">
                        This field is requird
                    </small>
                    <small *ngIf="form.get('email')?.errors?.['email']" class="text-danger">
                        Enter a valid email
                    </small>
                </div>
            </div>
            <div class="input-field position-relative mt-4">
                <div class="position-relative">
                    <input placeholder="Enter your Password" class="form-control mh-45-px " id="password"
                        formControlName="password" [type]="show ? 'text' : 'password'" formcontrolname="password">
                    <span class="password-toggle-icon">
                        <a role="button" (click)="show = !show">
                            <i [class]="show ? 'ri-eye-off-line' : 'ri-eye-line'"></i>
                        </a>
                    </span>
                </div>
                <div class="form-control-feedback"
                    *ngIf="((form.get('password')?.touched) && (form.get('password')?.invalid))">
                    <small *ngIf="form.get('password')?.errors?.['required']" class="text-danger">
                        This field is requird
                    </small>
                    <small *ngIf="form.get('password')?.errors?.['minlength']" class="text-danger">
                        Password must be at least 6 characters
                    </small>
                </div>

            </div>
            <!-- <div class="forgetPass text-end mt-3 mb-2">
                <a routerlink="/password/forget-password">
                    Forgot Password ?
                </a>
            </div> -->
            <div class=" mt-3">
                <button type="submit" class=" btn btn-primary justify-content-center w-100 mh-45-px"
                    [disabled]="loading">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    login
                </button>
            </div>
        </form>
    </div>
</div>