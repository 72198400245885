import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  today = new Date();
  currentUser: any;
  activeRoute: any;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private tostr: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    this.currentUser = this.authService.currentUserSubject.value;
    console.log('currentUser', this.currentUser);
    this.activeRoute = this.route.snapshot.data['title'];
    console.log(this.currentUser);
    console.log(this.activeRoute);
  }

  logout() {
    this.authService.logout(this.route.snapshot.data['title']).subscribe({
      next: () => {
        localStorage.removeItem('currentUser');
        this.authService.currentUserSubject.next(null);
        this.tostr.success('Logged out successfully');
        this.router.navigate(['/login']);
      },
      error: (error) => {
        console.log('Error while logging out', error);
      },
    });
  }
}
