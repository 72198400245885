import { Component } from '@angular/core';
import { BrandsService } from '../../services/brands.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddBrandComponent } from './add-brand/add-brand.component';
@Component({
  selector: 'lib-brands',
  templateUrl: './brands.component.html',
  styleUrl: './brands.component.scss',
})
export class BrandsComponent {
  brands: any;
  bsModalRef?: BsModalRef;

  constructor(
    private BrandsService: BrandsService,
    private modalService: BsModalService
  ) {}
  ngOnInit() {
    this.getBrands();
  }
  getBrands() {
    this.BrandsService.getBrands().subscribe({
      next: (data) => {
        this.brands = data.data;
        console.log('Brands data', this.brands);
      },
      error: (error) => {
        console.log('Error while fetching brands data', error);
      },
    });
  }
  openAddBrandModal() {
    this.bsModalRef = this.modalService.show(AddBrandComponent, {
      class: 'add-brand-modal',
    });
    this.bsModalRef.onHidden?.subscribe((reason: any) => {
      if (reason === 'getBrands' || reason === 'brand deleted') {
        this.getBrands();
      }
    });
  }
}
