<div class="sidebar custom-sidebar">
    <ul class="navbar-nav">
        <li class="nav-item" *ngFor="let route of routes">
            <a class="nav-link text-center" [routerLink]="route.route" routerLinkActive="active">
                <i [class]="route.icon"></i>
                <span> {{ route.title }} </span>
            </a>
        </li>
        <!-- <li class="nav-item">
            <a class="nav-link text-center" routerLink="/orders" routerLinkActive="active">
                <i class="flaticon-shopping-cart"></i>
                <span class="d-block"> Orders </span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link text-center" routerLink="/customers" routerLinkActive="active">
                <i class="flaticon-user"></i>
                <span class="d-block"> CRM </span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link text-center" routerLink="/company" routerLinkActive="active">
                <i class="flaticon-management"></i>
                <span class="d-block"> Companies </span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link text-center" href="">
                <i class="flaticon-celebrity"></i>
                <span class="d-block"> Celebrities </span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link text-center" href="">
                <i class="flaticon-categories"></i>
                <span class="d-block"> Categories </span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link text-center" href="">
                <i class="flaticon-setting"></i>
                <span class="d-block"> Inventory </span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link text-center" href="">
                <i class="flaticon-inventory"></i>
                <span class="d-block"> Orders </span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link text-center" href="">
                <i class="flaticon-webpage"></i>
                <span class="d-block"> Offers </span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link text-center" href="">
                <i class="flaticon-megaphone"></i>
                <span class="d-block"> Ads </span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link text-center" routerLink="/settings" routerLinkActive="active">
                <i class="flaticon-settings"></i>
                <span class="d-block"> Settings </span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link text-center" href="">
                <i class="flaticon-chat"></i>
                <span class="d-block"> Contact Message </span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link text-center" href="">
                <i class="flaticon-crown"></i>
                <span class="d-block"> Admin list </span>
            </a>
        </li> -->
    </ul>
</div>