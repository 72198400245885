import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomImagePipe } from './image.pipe';

@NgModule({
  declarations: [CustomImagePipe],
  exports: [CustomImagePipe],
  imports: [CommonModule],
})
export class PipesModule {}
