import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/src';

@Injectable({
  providedIn: 'root',
})
export class BankAccountService {
  constructor(private http_client: HttpClient) {}

  getBanks(type: string): Observable<any> {
    const URL = `${environment.BASE_URL}${type}/bank-names`;
    return this.http_client.get<any>(URL);
  }

  getMyBankAccounts(type: string): Observable<any> {
    const URL = `${environment.BASE_URL}${type}/mybanks`;
    return this.http_client.get<any>(URL);
  }

  addBankAccount(data: any, type: string): Observable<any> {
    const URL = `${environment.BASE_URL}${type}/create_bank_account`;
    return this.http_client.post<any>(URL, data);
  }

  deleteBankAccount(id: string, type: string): Observable<any> {
    const URL = `${environment.BASE_URL}${type}/delete_bank_account/${id}`;
    return this.http_client.post<any>(URL, { id });
  }

  editBankAccount(id: string, data: any, type: string): Observable<any> {
    const URL = `${environment.BASE_URL}${type}/update_bank_account/${id}`;
    return this.http_client.post<any>(URL, data);
  }

  getBankAccountById(id: string, type: string): Observable<any> {
    const URL = `${environment.BASE_URL}${type}/mybanks/${id}`;
    return this.http_client.get<any>(URL);
  }
}
