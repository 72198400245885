import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { catchError, Observable, of } from 'rxjs';
import Swal from 'sweetalert2';
import { BankAccountService } from '../../../services/bank-account.service';
import { AddBankAccountComponent } from './add-bank-account/add-bank-account.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-banks',
  templateUrl: './banks.component.html',
  styleUrl: './banks.component.scss',
})
export class BanksComponent {
  myBankAccounts$: Observable<any> | undefined;
  bsModalRef?: BsModalRef;
  title: string;
  constructor(
    private bankAccountService: BankAccountService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.title = this.route.snapshot.parent?.data['title'];
    this.getMyBankAccounts();
  }
  addBankAccount() {
    this.bsModalRef = this.modalService.show(AddBankAccountComponent, {
      initialState: {
        type: 'create',
        title: this.title,
      },
    });
    this.bsModalRef.onHidden?.subscribe((reason: any) => {
      if (reason === 'getMyBankAccounts') {
        this.getMyBankAccounts();
      }
    });
  }

  editBankAccount(bankAccount: any) {
    this.bsModalRef = this.modalService.show(AddBankAccountComponent, {
      initialState: {
        type: 'edit',
        bankAccount,
        title: this.title,
      },
    });
    this.bsModalRef.onHidden?.subscribe((reason: any) => {
      if (reason === 'getMyBankAccounts') {
        this.getMyBankAccounts();
      }
    });
  }

  deleteBankAccount(bankAccountId: string) {
    this.bankAccountService
      .deleteBankAccount(bankAccountId, this.title)
      .subscribe(() => {
        this.getMyBankAccounts();
      });
    this.toastr.success('Bank account deleted successfully');
    this.bsModalRef?.hide();
  }
  getMyBankAccounts() {
    this.myBankAccounts$ = this.bankAccountService
      .getMyBankAccounts(this.title)
      .pipe(
        catchError((err: any) => {
          return of(err);
        })
      );
  }
  confirmDelete(bankAccountId: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this bank account!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    }).then((result) => {
      if (result.value) {
        this.deleteBankAccount(bankAccountId);
      }
    });
  }
  updateDefault(bankAccount: any) {
    const {
      user_id,
      updated_at,
      created_at,
      default_account,
      bank_name,
      ...rest
    } = bankAccount;
    this.bankAccountService
      .editBankAccount(
        bankAccount.id,
        {
          ...rest,
          default_account: !default_account,
        },
        this.title
      )
      .subscribe({
        next: () => {
          this.getMyBankAccounts();
          this.toastr.success('Default bank account updated successfully');
        },
        error: (err) => {
          console.log('err', err);
        },
      });
  }
}
