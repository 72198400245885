<div class="container-fluid">
    <div class="table-filter my-4">
        <!-- <form action="">
            <div class="row">
                <div class="col-lg-11 col-md-6">
                    <div class="form-group mb-3 mb-lg-0">
                        <input type="text" name="search" class="form-control rounded-2"
                            placeholder="Search by name or order ID">
                    </div>
                </div>
                <div class="col-lg-1 ">
                    <div class="row">
                        <div class="">
                            <button
                                class="btn bg-white filter-btn w-100 mb-3 mb-lg-0 d-flex align-items-center justify-content-center ">
                                <i class="flaticon-down"></i>
                                <span>
                                    sort
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form> -->
        <div class="d-flex justify-content-end align-items-center mt-5">
            <!-- <div class="mb-2">
                <small class="text-secondary me-2"> show </small>
                <select name="show" id="" class="form-select d-inline-block w-10">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                </select>
                <small class="text-secondary ms-2">Entries</small>
            </div> -->
            <div>
                <a routerLink="/transactions/request-withdraw" class="btn btn-primary d-flex align-items-center">
                    <span>
                        request withdraw
                    </span>
                    <i class="flaticon-plus fs-6 d-inline-block ms-2 "></i>
                </a>
            </div>
        </div>
    </div>
    <div class="table-responsive custom-table" *ngIf="cashouts$ | async as cashouts">
        @if (cashouts['data'].length > 0) {
        <table class="w-100 border-2 border-secondary">
            <thead>
                <tr>
                    <th><input type="checkbox" class="form-check d-inline-block"></th>
                    <th>SN</th>
                    <!-- <th>Method</th> -->
                    <!-- <th>Charge</th> -->
                    <!-- <th>Total Amount</th> -->
                    <th>Withdraw Amount</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let cashout of cashouts['data']">
                    <td><input type="checkbox" class="form-check d-inline-block"></td>
                    <td>{{cashout.id}}</td>
                    <td>{{cashout.amount}}</td>
                    <td>
                        <div class="status">
                            <span [ngClass]="cashout.status == '' ? 'declined' : cashout.status">
                                {{cashout.status == '' ? 'deleted' : cashout.status}}
                            </span>
                        </div>
                    </td>
                    <td>
                        {{cashout.created_at | date: 'medium'}}
                    </td>
                    <td>
                        @if(cashout.status === 'pending') {
                        Not updated yet
                        }
                        @else if (
                        cashout.status === 'declined'
                        ) {
                        {{cashout.declined_date | date: 'medium'}}
                        }
                        @else {
                        {{cashout.approved_date | date: 'medium'}}
                        }
                    </td>
                    <td>
                        <div *ngIf="cashout.status === 'pending'">
                            <a class="me-3" role="button" (click)="updateCashout(cashout)">
                                <i class="flaticon-edit fs-6"></i>
                            </a>
                            <a class="text-danger" role="button" (click)="confrimDeleteCashout(cashout.id)">
                                <i class="flaticon-delete fs-6"></i>
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>}
        @else {
        <div class="text-center">
            <p class="text-secondary">No cashouts found</p>
        </div>
        }
    </div>

</div>