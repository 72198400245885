import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TransactionsRoutingModule } from './transactions-routing.module';
import { TransactionsComponent } from './transactions.component';
import { BanksComponent } from './banks/banks.component';
import { WithdrawRequestComponent } from './withdrawals/withdraw-request/withdraw-request.component';
import { WithdrawalsComponent } from './withdrawals/withdrawals.component';
import { AddBankAccountComponent } from './banks/add-bank-account/add-bank-account.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    TransactionsComponent,
    WithdrawalsComponent,
    WithdrawRequestComponent,
    BanksComponent,
    AddBankAccountComponent,
  ],
  imports: [
    CommonModule,
    TransactionsRoutingModule,
    TabsModule,
    NgSelectModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
  ],
})
export class TransactionsModule {}
