import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrl: './full-layout.component.scss',
})
export class FullLayoutComponent {
  // routes: routes[] = [];
  isAdmin: boolean = this.route.snapshot.data['title'] == 'admin';
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    console.log(this.route.snapshot.data['title']);
  }
}
