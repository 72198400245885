import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/src';
import { Observable } from 'rxjs';
import { CelebrityResponse } from '../types/celebrity-dashboard.types';

@Injectable({
  providedIn: 'root',
})
export class CelebrityService {
  constructor(private http_client: HttpClient) {}

  getCelebrityDashboard(): Observable<CelebrityResponse> {
    const URL = `${environment.BASE_URL}affiliate/dashboard`;
    return this.http_client.get<CelebrityResponse>(URL);
  }

  getCelebrityProfile(): Observable<any> {
    const URL = `${environment.BASE_URL}affiliate/profile`;
    return this.http_client.get<any>(URL);
  }
  updateCelebrityProfile(data: any) {
    const URL = `${environment.BASE_URL}affiliate/update-my-profile`;
    return this.http_client.post(URL, data);
  }
  getCategoryList(): Observable<any> {
    const URL = `${environment.BASE_URL}affiliate/get-category-affiliates`;
    return this.http_client.get<any>(URL);
  }
  getSubCategoryList(category: any): Observable<any> {
    const URL = `${environment.BASE_URL}affiliate/get-sub-category-affiliates/${category}`;
    return this.http_client.get<any>(URL);
  }
  getActiveProduct() {
    const URL = `${environment.BASE_URL}affiliate/affiliate_products`;
    return this.http_client.get<any>(URL);
  }
  getPendingProduct() {
    const URL = `${environment.BASE_URL}affiliate/affiliate_pending_products`;
    return this.http_client.get<any>(URL);
  }
  updateProductStatus(id: string) {
    const data = {
      _method: 'PUT',
    };
    const URL = `${environment.BASE_URL}affiliate/product-product-status/${id}`;
    return this.http_client.post(URL, data);
  }
  deletProduct(id: string) {
    const data = {
      _method: 'DELETE',
    };
    const URL = `${environment.BASE_URL}affiliate/products/destroy/${id}`;
    return this.http_client.post(URL, data);
  }
}
