<div>
    <div class="nav-tabs">
        <ul>
            <li class="nav-item">
                <a class="nav-link" routerLink="withdrawal" routerLinkActive="active">Withdrawals</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="banks" routerLinkActive="active">Banks</a>
            </li>
        </ul>
    </div>
    <router-outlet></router-outlet>
</div>