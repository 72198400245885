import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { environment } from '../../environments/src';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { PipesModule } from '../../pipes/pipes.module';
import { CelebrityService } from '../../services/celebrity.service';

@Component({
  selector: 'lib-products-table',
  standalone: true,
  imports: [CommonModule, PipesModule, TooltipModule, ReactiveFormsModule],
  templateUrl: './products-table.component.html',
  styleUrl: './products-table.component.scss',
})
export class ProductsTableComponent {
  @Input() products: any[] = [];
  @Input() type: string = '';
  productURL = environment.WEBSITE_URL + 'products/details/';
  constructor(
    private celebrityService: CelebrityService,
    private tostar: ToastrService
  ) {}

  changeStatus(id: string) {
    this.celebrityService.updateProductStatus(id).subscribe({
      next: (res) => {
        this.tostar.success('Product status updated successfully');
        this.products = this.products.filter(
          (product) => product.product_id !== id
        );
      },
      error: (err) => {
        console.log('err', err);
      },
    });
  }
  deleteProduct(id: string) {
    this.celebrityService.deletProduct(id).subscribe({
      next: (res) => {
        this.tostar.success('Product deleted successfully');
        this.products = this.products.filter(
          (product) => product.product_id !== id
        );
      },
      error: (err) => {
        console.log('err', err);
      },
    });
  }

  confirmDelete(product_id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this product!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    }).then((result) => {
      if (result.value) {
        this.deleteProduct(product_id);
      }
    });
  }
}
