<div class="edit__affiliate py-3" id="edit__affiliate">
    <div class="container-fluid">
        <form [formGroup]="personalInfo">
            <div class="row">
                <div class="col-md-7">
                    <div class="card imgs-card p-0">
                        <div class="card-body pb-5">
                            <div class="banner position-relative">
                                <div class="img-container ">
                                    <div class="input-file">
                                        <label for="banner" class="d-flex align-items-center justify-content-center">
                                            <i class="flaticon-camera"></i> </label>
                                        <input id="banner" type="file" accept="image/*" class="form-control-file"
                                            name="banner" (change)="onFileChange($event, '0','banner_image')">
                                    </div>
                                    <img id="bannerImg"
                                        [src]="currentUser.affiliate.banner_image ? (currentUser.affiliate.banner_image | customImage) : './assets/images/bannerImage.png'">
                                </div>
                                <div class=" logo">
                                    <div class="img-container">
                                        <div class="input-file">
                                            <label for="logo" class="d-flex align-items-center justify-content-center">
                                                <i class="flaticon-camera"></i> </label>
                                            <input id="logo" type="file" accept="image/*" class="form-control-file"
                                                name="logo" (change)="onFileChange($event, '1','logo_image')">
                                        </div>
                                        <img id="logoImg"
                                            [src]="currentUser.affiliate.logo ? (currentUser.affiliate.logo | customImage) : './assets/images/dummyLogo.png' ">
                                    </div>
                                </div>
                            </div>
                            <!-- <span class="bio">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                Ipsum
                            </span> -->
                        </div>
                    </div>

                </div>
                <!-- End of image and bio div -->
                <div class="col-md-5">
                    <div class="card">
                        <div class="card-header p-3">
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="card-title"> <i class="ri-user-line"></i> Personal data</h5>
                                <button class="btn btn-primary d-inline-block" (click)="updateChanges()">Save
                                    Changes
                                </button>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="mb-3">
                                    <label class="form-label">Celebrity Name <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" name="shop_name" formControlName="name">
                                    <div class="form-control-feedback"
                                        *ngIf="((personalInfo.get('name')?.touched) && (personalInfo.get('name')?.invalid))">
                                        <small *ngIf="personalInfo.get('name')?.errors?.['required']"
                                            class="text-danger">
                                            This field is requird
                                        </small>
                                    </div>
                                </div>

                                <div class="mb-3 col-lg-6 ">
                                    <label class="form-label">Email <span class="text-danger">*</span></label>
                                    <input type="email" class="form-control" name="email" formControlName="email">
                                    <div class="form-control-feedback"
                                        *ngIf="((personalInfo.get('email')?.touched) && (personalInfo.get('email')?.invalid))">
                                        <small *ngIf="personalInfo.get('email')?.errors?.['required']"
                                            class="text-danger">
                                            This field is requird
                                        </small>
                                        <small *ngIf="personalInfo.get('email')?.errors?.['email']" class="text-danger">
                                            Enter a valid Email
                                        </small>
                                    </div>
                                </div>

                                <div class="mb-3  col-lg-6">
                                    <label class="form-label">Phone <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" name="phone" formControlName="phone">
                                    <div class="form-control-feedback"
                                        *ngIf="((personalInfo.get('phone')?.touched) && (personalInfo.get('phone')?.invalid))">
                                        <small *ngIf="personalInfo.get('phone')?.errors?.['required']"
                                            class="text-danger">
                                            This field is requird
                                        </small>
                                        <small *ngIf="personalInfo.get('phone')?.errors?.['phone']" class="text-danger">
                                            Enter a valid Phone
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="mb-3 col-lg-6" *ngIf="categories$ | async as categories">
                                    <label class="form-label"> Type <span class="text-danger">*</span></label>
                                    <ng-select (ngModelChange)="onCategoryChange($event)" [multiple]="false"
                                        name="country_id" formControlName="category_id">
                                        <ng-option value="">Select Category</ng-option>
                                        <ng-option *ngFor="let category of categories.data?.affiliate_types"
                                            [value]="category.id">
                                            {{ category.name }}
                                        </ng-option>
                                    </ng-select>
                                    <div class="form-control-feedback"
                                        *ngIf="((personalInfo.get('category_id')?.touched) && (personalInfo.get('category_id')?.invalid))">
                                        <small *ngIf="personalInfo.get('category_id')?.errors?.['required']"
                                            class="text-danger">
                                            This field is requird
                                        </small>
                                    </div>
                                </div>
                                <div class="mb-3 col-lg-6" *ngIf="subCategories$ | async as subCategories">
                                    <label class="form-label"> Subtype <span class="text-danger">*</span></label>
                                    <ng-select name="country_id" formControlName="sub_category_id">
                                        <ng-option value="">Select Subtype</ng-option>
                                        <ng-option *ngFor="let subCategory of subCategories.data?.affiliate_sub_types"
                                            [value]="subCategory.id">
                                            {{ subCategory.name }}
                                        </ng-option>
                                    </ng-select>
                                    <div class="form-control-feedback"
                                        *ngIf="((personalInfo.get('sub_category_id')?.touched) && (personalInfo.get('sub_category_id')?.invalid))">
                                        <small *ngIf="personalInfo.get('sub_category_id')?.errors?.['required']"
                                            class="text-danger">
                                            This field is requird
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Seo Title</label>
                                <input type="text" class="form-control" name="seo_title" formControlName="seo_title">
                                <div class="form-control-feedback"
                                    *ngIf="((personalInfo.get('seo_title')?.touched) && (personalInfo.get('seo_title')?.invalid))">
                                    <small *ngIf="personalInfo.get('seo_title')?.errors?.['required']"
                                        class="text-danger">
                                        This field is requird
                                    </small>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Seo Description</label>
                                <textarea name="seo_description" class="form-control text-area-5" id=""
                                    formControlName="seo_description" rows="5"></textarea>
                                <div class="form-control-feedback">
                                    <small *ngIf="personalInfo.get('seo_description')?.errors?.['required']"
                                        class="text-danger">
                                        This field is requird
                                    </small>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body p-3">
                            <div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <h5 class="card-title"> <i class="ri-user-line"></i> Password</h5>
                                    <button class="btn btn-primary d-inline-block" (click)="updatePassword()">Save
                                        Changes
                                    </button>
                                </div>
                                <form [formGroup]="passwordForm">
                                    <div class="form-group mb-3">
                                        <label class="form-label"> Password <span class="text-danger">*</span></label>

                                        <div class="position-relative">
                                            <input [type]="showPassword ? 'password': 'text'" class="form-control"
                                                name="password" formControlName="password">
                                            <span class="password-toggle-icon">
                                                <a role="button" (click)="showPassword = !showPassword">
                                                    <i [class]="showPassword ? 'ri-eye-off-line' : 'ri-eye-line'"></i>
                                                </a>
                                            </span>
                                        </div>
                                        <div class="form-control-feedback"
                                            *ngIf="((passwordForm.get('password')?.touched) && (passwordForm.get('password')?.invalid))">
                                            <small *ngIf="passwordForm.get('password')?.errors?.['required']"
                                                class="text-danger">
                                                This field is requird
                                            </small>
                                        </div>
                                        <small *ngIf="passwordForm.get('password')?.errors?.['password']"
                                            class="text-danger">
                                            Password must be at least 6 characters long
                                        </small>
                                    </div>
                                    <div class="form-group mb-3">
                                        <label class="form-label">Confirm Password <span
                                                class="text-danger">*</span></label>

                                        <div class="position-relative">
                                            <input [type]="showConfirmPassword ? 'password': 'text'"
                                                class="form-control" name="password" formControlName="confirm_password">
                                            <span class="password-toggle-icon">
                                                <a role="button" (click)="showConfirmPassword = !showConfirmPassword">
                                                    <i
                                                        [class]="showConfirmPassword ? 'ri-eye-off-line' : 'ri-eye-line'"></i>
                                                </a>
                                            </span>
                                        </div>
                                        <div class="form-control-feedback"
                                            *ngIf="((passwordForm.get('confirm_password')?.touched) && (passwordForm.get('confirm_password')?.invalid))">
                                            <small *ngIf="passwordForm.get('confirm_password')?.errors?.['required']"
                                                class="text-danger">
                                                This field is requird
                                            </small>
                                            <small
                                                *ngIf="passwordForm.get('password')?.value !== passwordForm.get('confirm_password')?.value"
                                                class="text-danger d-block">
                                                Password and Confirm Password do not match
                                            </small>
                                            <small *ngIf="passwordForm.get('confirm_password')?.errors?.['password']"
                                                class="text-danger">
                                                Password must be at least 6 characters long
                                            </small>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- <lib-create-manager></lib-create-manager> -->
                </div>
            </div>
        </form>
    </div>
</div>