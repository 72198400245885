<div class="card">
    <div class="card-body">
        <div class="img-container">
            <img [src]="brand.logo | customImage" alt="">
            <div class="delete-icon">
                <a role="button" class="text-danger" (click)="confirmDelete(brand.id)">
                    <i class="flaticon-delete"></i>
                </a>
            </div>
        </div>
        <div>
            <div class="d-flex justify-content-between align-items-center">
                <div class="mt-3">
                    <h6 class="m-0">
                        {{brand.name}}
                    </h6>
                    <span class="text-muted">
                        {{brand.categories[0]?.name ? brand.categories[0]?.name : '-'}}
                    </span>
                </div>
                <div>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" [id]="brand.name + brand.id"
                            [checked]="brand.status" (change)="changeStatus(brand.id)">
                        <label class="form-check-label" [ngClass]="brand.status ? 'text-success' :'text-danger'"
                            [for]="brand.name + brand.id">
                            {{brand.status ? 'Active' : 'Inactive'}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="w-100 my-3">
                <a [routerLink]="'/brands/brand-details/' + brand.id" class="btn btn-primary w-100">
                    view
                </a>
            </div>
        </div>
    </div>
</div>