import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
// import { HelperToolsService } from './helper-tools.service';

@Injectable({
  providedIn: 'root',
})
export class isLoggedIn {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<boolean>((resolve, reject) => {
      let token = this.auth.currentUserSubject.value
        ? this.auth.currentUserSubject.value.token?.access_token
        : '';
      if (token) {
        if (route.data['title'] === 'affiliate') {
          this.router.navigate(['/celebrities']);
        } else if (route.data['title'] === 'seller') {
          this.router.navigate(['/company/details']);
        }
        resolve(false);
      } else {
        resolve(true);
      }
    });
  }
}
