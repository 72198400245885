import { Component } from '@angular/core';
import { CelebrityService } from '../../../services/celebrity.service';
import { catchError, Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { CashoutService } from '../../../services/cashouts.service';

@Component({
  selector: 'lib-withdrawals',
  templateUrl: './withdrawals.component.html',
  styleUrl: './withdrawals.component.scss',
})
export class WithdrawalsComponent {
  cashouts$: Observable<any> | undefined;
  routeData: any;
  constructor(
    private celebrityService: CelebrityService,
    private router: Router,
    private tostar: ToastrService,
    private route: ActivatedRoute,
    private cashoutService: CashoutService
  ) {
    this.routeData = this.route.snapshot.parent?.data['title'];
  }
  ngOnInit() {
    this.cashouts$ = this.cashoutService.getCashouts(this.routeData).pipe(
      catchError((err: any) => {
        return of(err);
      })
    );
  }
  updateCashout(data: any) {
    this.router.navigate(['/transactions/request-withdraw'], {
      state: { data: data, type: 'update' },
    });
  }
  deleteCashout(id: string) {
    this.cashoutService.deleteCashout(id, this.routeData).subscribe({
      next: (res) => {
        this.cashouts$ = this.cashoutService.getCashouts(this.routeData).pipe(
          catchError((err: any) => {
            return of(err);
          })
        );
        this.tostar.success('Withdraw request deleted successfully');
      },
      error: (err) => {
        console.log('err', err);
      },
    });
  }
  confrimDeleteCashout(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this cashout!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    }).then((result) => {
      if (result.value) {
        this.deleteCashout(id);
      }
    });
  }
}
