import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BrandsService } from '../../../services/brands.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ValidateFormService } from '../../../services/validate-form.service';

@Component({
  selector: 'lib-add-brand',
  templateUrl: './add-brand.component.html',
  styleUrl: './add-brand.component.scss',
})
export class AddBrandComponent {
  cats: any;
  form: FormGroup = {} as FormGroup;
  constructor(
    private brandsService: BrandsService,
    private fb: FormBuilder,
    private tostar: ToastrService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private validateFormService: ValidateFormService
  ) {}
  ngOnInit() {
    this.form = this.fb.group({
      logo: ['', Validators.required],
      name: ['', Validators.required],
      slug: ['', Validators.required],
      categories: [''],
      status: [1, Validators.required],
    });
    this.brandsService.getBrandCategories().subscribe({
      next: (data: any) => {
        this.cats = data.data;
        console.log('Categories data', this.cats);
      },
      error: (error) => {
        console.log('Error while fetching categories data', error);
      },
    });
  }
  addBrand() {
    if (this.form.invalid) {
      return this.validateFormService.validateAllFormFields(this.form);
    }
    const formData = new FormData();
    for (const key in this.form.value) {
      formData.append(key, this.form.value[key]);
    }
    this.brandsService.createBrand(formData).subscribe({
      next: (data) => {
        this.tostar.success('Brand added successfully');
        console.log('Brand added successfully', data);
        this.bsModalRef.hide();
        this.modalService.setDismissReason('getBrands');
      },
      error: (error) => {
        this.tostar.error(error.error.errors.name);
        console.log('Error while adding brand', error);
      },
    });
  }
  onFileChange(event: any, index: any, formControl: any): void {
    console.log('event', event);
    this.form.get(formControl)?.patchValue(event.target.files[0]);
    console.log('index', index);
    if (index) {
      const doc = document.getElementById('add_brand');
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        let img = (doc?.getElementsByTagName('img') as any)[index];
        img.src = _event.target?.result;
      };
    }
  }
}
