import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActiveProductsComponent } from './active-products/active-products.component';
import { ProductsComponent } from './products.component';
import { PendingProductsComponent } from './pending-products/pending-products.component';

const routes: Routes = [
  {
    path: '',
    component: ProductsComponent,
    children: [
      { path: 'active-products', component: ActiveProductsComponent },
      { path: 'pending-products', component: PendingProductsComponent },
      { path: '', redirectTo: 'active-products', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductsRoutingModule {}
