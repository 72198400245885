import { Component, Input } from '@angular/core';
import { CelebrityService } from '../../../services/celebrity.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lib-active-products',
  templateUrl: './active-products.component.html',
  styleUrl: './active-products.component.scss',
})
export class ActiveProductsComponent {
  products: any[] = [];
  active: string = 'active';

  constructor(private celebrityService: CelebrityService) {}
  subscription: Subscription = new Subscription();

  ngOnInit() {
    this.subscription = this.celebrityService
      .getActiveProduct()
      .subscribe((res) => {
        this.products = res.data.data;
      });
  }
  ngOnDestroy() {
    this.products = [];
    this.subscription.unsubscribe();
  }
}
