import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompaniesRoutingModule } from './companies-routing.module';
import { CompanyDetailsComponent } from './company-details.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipesModule } from '../../pipes/pipes.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CompanyEditComponent } from './company-edit/company-edit.component';

@NgModule({
  declarations: [CompanyDetailsComponent, CompanyEditComponent],
  imports: [
    CommonModule,
    CompaniesRoutingModule,
    TooltipModule.forRoot(),
    PipesModule,
    ReactiveFormsModule,
  ],
})
export class CompaniesModule {}
