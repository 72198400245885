import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';
import { CelebrityService } from '../../../services/celebrity.service';
import { ValidateFormService } from '../../../services/validate-form.service';

@Component({
  selector: 'lib-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent {
  public personalInfo: FormGroup;
  public passwordForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private celebrityService: CelebrityService,
    private toastr: ToastrService,
    private validateFormService: ValidateFormService
  ) {
    this.personalInfo = this.fb.group({
      name: ['', [Validators.required]],
      phone: [
        '',
        [Validators.required, Validators.pattern('^[0-9]*$')],
        // Validators.minLength(10),
      ],
      address: ['', [Validators.required]],
      logo: ['', [Validators.required]],
      greeting_msg: ['', [Validators.required]],
      shop_name: ['', [Validators.required]],
      open_at: ['', [Validators.required]],
      closed_at: ['', [Validators.required]],
      status: ['', [Validators.required]],
      banner_image: ['', [Validators.required]],
      seo_title: ['', [Validators.required, Validators.maxLength(70)]],
      seo_description: ['', [Validators.required, Validators.maxLength(160)]],
      email: ['', [Validators.required, Validators.email]],
      category_id: ['', [Validators.required]],
      sub_category_id: ['', [Validators.required]],
    });
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }
  currentUser = this.authService.currentUserSubject.value;
  showPassword = true;
  showConfirmPassword = true;
  categories$: Observable<any> | undefined;
  subCategories$: Observable<any> | undefined;
  ngOnInit() {
    this.personalInfo.patchValue(this.currentUser.affiliate);
    this.categories$ = this.celebrityService.getCategoryList();
    this.subCategories$ = this.celebrityService.getSubCategoryList(
      this.personalInfo.value.category_id
    );
  }

  onFileChange(event: any, index: any, formControl: any): void {
    this.personalInfo.get(formControl)?.patchValue(event.target.files[0]);
    if (index) {
      const doc = document.getElementById('edit__affiliate');
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        let img = (doc?.getElementsByTagName('img') as any)[index];
        img.src = _event.target?.result;
      };
    }
  }
  updateChanges() {
    if (this.personalInfo.invalid) {
      return this.validateFormService.validateAllFormFields(this.personalInfo);
    }
    // return console.log('personalInfo', this.personalInfo.value);
    const formData = new FormData();
    for (const key in this.personalInfo.value) {
      if (this.personalInfo.value.hasOwnProperty(key)) {
        formData.append(key, this.personalInfo.value[key]);
      }
    }
    formData.append('_method', 'PUT');
    this.celebrityService.updateCelebrityProfile(formData).subscribe({
      next: (res: any) => {
        console.log('res', res);
        this.toastr.success(res.message);
        // this.currentUser = res.affiliate;
        // this.authService.currentUserSubject.next(this.currentUser);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
  updatePassword() {
    if (!this.passwordForm.valid) {
      return this.validateFormService.validateAllFormFields(this.passwordForm);
    }
    const formData = new FormData();
    formData.append('password', this.passwordForm.value.password);
    formData.append(
      'confirm_password',
      this.passwordForm.value.confirm_password
    );
    formData.append('_method', 'PUT');
    this.authService.updatePassword(formData, 'affiliate').subscribe({
      next: (res: any) => {
        console.log('res', res);
        this.toastr.success('Password updated successfully');
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
  onCategoryChange(category: any) {
    if (category == '') {
      this.subCategories$ = of([]);
    } else {
      this.subCategories$ = this.celebrityService.getSubCategoryList(category);
    }

    this.personalInfo.get('sub_category_id')?.patchValue('');
  }
}
