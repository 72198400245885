<div class="py-3" id="edit_profile">
    <form [formGroup]="changeCompanyInfo">
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="logo">
                                <h6 class="mb-3 fw-bold ">
                                    Current logo
                                </h6>
                                <div class="img-container currentPic">
                                    <img [src]="currentUser.vendor.logo ? (currentUser.vendor.logo | customImage) : './assets/images/dummyLogo.png' "
                                        alt="company-logo">
                                </div>
                                <div class="my-3">
                                    <label for="brandlogo"
                                        class="btn btn-outline-secondary w-100 d-flex justify-content-center align-items-center">
                                        <i class="flaticon-upload lh-1"></i>
                                        <span class="ms-2">
                                            Add Image
                                        </span>
                                    </label>
                                    <input class="d-none" type="file" id="brandlogo"
                                        (change)="onFileChange($event, '0','logo')">
                                </div>
                            </div>
                        </div>
                        <div class=" col-lg-9">
                            <div class="banner">
                                <h6 class="mb-3 fw-bold ">
                                    Current Banner Image
                                </h6>
                                <div class="img-container currentPic">
                                    <img [src]="currentUser.vendor.banner_image ? (currentUser.vendor.banner_image | customImage) : './assets/images/dummyBanner.jpg' "
                                        alt="company-banner">
                                </div>
                                <div class="my-3">
                                    <label for="banner_image"
                                        class="btn btn-outline-secondary w-100 d-flex justify-content-center align-items-center">
                                        <i class="flaticon-upload lh-1"></i>
                                        <span class="ms-2">
                                            Add Image
                                        </span>
                                    </label>
                                    <input class="d-none" type="file" id="banner_image"
                                        (change)="onFileChange($event, '1','banner_image')">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row justify-content-between mb-4">
                        <div class="col-lg-5">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="shop-name" class="form-label">Shop Name</label>
                                        <input type="text" class="form-control" id="shopName" formControlName="name"
                                            placeholder="Sand Box">
                                        <div class="form-control-feedback"
                                            *ngIf="((changeCompanyInfo.get('name')?.touched) && (changeCompanyInfo.get('name')?.invalid))">
                                            <small *ngIf="changeCompanyInfo.get('name')?.errors?.['required']"
                                                class="text-danger">
                                                This field is requird
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="email" class="form-label">Email</label>
                                        <input type="text" class="form-control" id="email" formControlName="email"
                                            placeholder="admin@Biofresh.com  ">
                                        <div class="form-control-feedback"
                                            *ngIf="((changeCompanyInfo.get('email')?.touched) && (changeCompanyInfo.get('email')?.invalid))">
                                            <small *ngIf="changeCompanyInfo.get('email')?.errors?.['required']"
                                                class="text-danger">
                                                This field is requird
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="phone" class="form-label">phone</label>
                                        <input type="number" class="form-control" id="phone" placeholder="Sand Box"
                                            formControlName="phone">
                                        <div class="form-control-feedback"
                                            *ngIf="((changeCompanyInfo.get('phone')?.touched) && (changeCompanyInfo.get('phone')?.invalid))">
                                            <small *ngIf="changeCompanyInfo.get('phone')?.errors?.['required']"
                                                class="text-danger">
                                                This field is requird
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="address" class="form-label">Address</label>
                                        <input type="text" class="form-control" id="address" formControlName="address"
                                            placeholder="admin@Biofresh.com  ">
                                        <div class="form-control-feedback"
                                            *ngIf="((changeCompanyInfo.get('address')?.touched) && (changeCompanyInfo.get('address')?.invalid))">
                                            <small *ngIf="changeCompanyInfo.get('address')?.errors?.['required']"
                                                class="text-danger">
                                                This field is requird
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row justify-content-between mb-4">
                        <div class="col-lg-5">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="openTime" class="form-label">Opens at</label>
                                        <input type="text" class="form-control" id="openTime" formControlName="open_at"
                                            placeholder="Sand Box">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="closeTime" class="form-label">Closed at</label>
                                        <input type="text" class="form-control" id="closeTime" formControlName="open_at"
                                            placeholder="admin@Biofresh.com">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="shop-name" class="form-label">Social icon</label>
                                        <input type="text" class="form-control" id="shopName" placeholder="Sand Box">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="email" class="form-label">Social Link </label>
                                        <input type="text" class="form-control" id="email" formControlName="email"
                                            placeholder="admin@Biofresh.com  ">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="form-group mb-4">
                        <label for="seo_title" class="form-label">Seo Title </label>
                        <input type="text" class="form-control" id="seo_title" formControlName="seo_title"
                            placeholder="admin@Biofresh.com  ">
                    </div>
                    <div class="form-group mb-4">
                        <label for="seo_description" class="form-label">Seo Decription </label>
                        <textarea rows="5" type="text" class="form-control" id="seo_description"
                            formControlName="seo_description" placeholder="Biofresh"></textarea>
                    </div> -->
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- <div>
                            <p for="active" class="form-label">
                                Status
                            </p>
                            <div>
                                <span class="text-success me-2"> Acive </span>
                                <div class="form-switch d-inline-block">
                                    <input class="form-check-input" type="checkbox" role="switch"
                                        id="flexSwitchCheckChecked" checked>
                                </div>
                            </div>
                        </div> -->
                        <div class="updateBtn">
                            <button class="btn btn-primary w-100" (click)="updatecompany()"> Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>