import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CelebrityDetailsComponent } from './celebrity-details.component';
import { ProfileComponent } from './profile/profile.component';
import { AddProductsComponent } from './add-products/add-products.component';

const routes: Routes = [
  { path: '', redirectTo: 'details', pathMatch: 'full' },
  { path: 'details', component: CelebrityDetailsComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'add-products', component: AddProductsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CelebritiesRoutingModule {}
