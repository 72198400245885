import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BankAccountService } from 'libs/shared-lib/src/lib/services/bank-account.service';
import { ValidateFormService } from 'libs/shared-lib/src/lib/services/validate-form.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-add-bank-account',
  templateUrl: './add-bank-account.component.html',
  styleUrl: './add-bank-account.component.scss',
})
export class AddBankAccountComponent {
  type: string = '';
  bankAccount: any;
  banks$: Observable<any> | undefined;
  title!: string;
  constructor(
    private bankAccountService: BankAccountService,
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private toastrService: ToastrService,
    private validateFormService: ValidateFormService,
    private modalService: BsModalService,
    private route: ActivatedRoute
  ) {}
  BankAccountForm = this.fb.group({
    swift_code: ['', [Validators.required]],
    iban: ['', [Validators.required]],
    account_number: ['', [Validators.required]],
    bank_id: ['', [Validators.required]],
    branch_name: ['', [Validators.required]],
    beneficiary_name: ['', [Validators.required]],
    default_account: ['', [Validators.required]],
  });
  ngOnInit(): void {
    if (this.type === 'edit') {
      this.BankAccountForm.patchValue(this.bankAccount);
    }
    this.banks$ = this.bankAccountService.getBanks(this.title);
  }

  onSubmit() {
    if (this.BankAccountForm.invalid) {
      return this.validateFormService.validateAllFormFields(
        this.BankAccountForm
      );
    }
    if (this.type === 'create') {
      this.bankAccountService
        .addBankAccount(this.BankAccountForm.value, this.title)
        .subscribe((res) => {
          console.log(res);
          this.bsModalRef.hide();
          this.modalService.setDismissReason('getMyBankAccounts');
          this.toastrService.success('Bank account added successfully');
        });
    }
    if (this.type === 'edit') {
      this.bankAccountService
        .editBankAccount(
          this.bankAccount.id,
          this.BankAccountForm.value,
          this.title
        )
        .subscribe((res) => {
          console.log(res);
          this.bsModalRef.hide();
          this.modalService.setDismissReason('getMyBankAccounts');
          this.toastrService.success('Bank account updated successfully');
        });
    }
  }
}
