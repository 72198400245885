<div class="py-3">
    <div class="container-fluid">
        <p class="fs-6 my-1">
            <a routerLink="/celebrities">
                Celebrity details
            </a>
            / products
        </p>
        <div class="nav-tabs my-3">
            <ul>
                <li class="nav-item">
                    <a class="nav-link" routerLink="active-products" routerLinkActive="active">
                        Active Products
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="pending-products" routerLinkActive="active">Pending Products</a>
                </li>
            </ul>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>