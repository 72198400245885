import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { catchError, Observable, of, Subscription } from 'rxjs';
import { environment } from '../../../environments/src';
import { ProductService } from '../../../services/product.service';
import { ConfirmProductsModalComponent } from '../confirm-products-modal/confirm-products-modal.component';

@Component({
  selector: 'lib-add-products',
  templateUrl: './add-products.component.html',
  styleUrl: './add-products.component.scss',
})
export class AddProductsComponent {
  products: any[] = [];
  bsModalRef?: BsModalRef<any>;
  productURL = environment.WEBSITE_URL + 'products/details/';
  totalItems = 0;
  currentPage = 1;
  itemsPerPage: any = 10;
  subscription: Subscription | undefined;
  constructor(
    public ProductService: ProductService,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) {}

  form = new FormGroup({
    search: new FormControl(''),
    sort: new FormControl(''),
    filter: new FormControl(''),
    per_page: new FormControl(10),
  });

  ngOnInit() {
    this.getProducts();
  }
  updatePerPage() {
    // this.itemsPerPage = ;
    this.getProducts();
  }
  addProductToCart(product: any) {
    if (!this.ProductService.selectedProducts.includes(product)) {
      this.ProductService.selectedProducts.push(product);
      this.ProductService.selectedProductsIds.push(product.id);
    }
  }
  removeProductFromCart(product: any) {
    this.ProductService.selectedProducts =
      this.ProductService.selectedProducts.filter(
        (element: any) => element.id !== product.id
      );
    this.ProductService.selectedProductsIds =
      this.ProductService.selectedProductsIds.filter(
        (element: any) => element !== product.id
      );
  }

  pageChanged(event: any) {
    this.currentPage = event.page;
    this.getProducts();
  }

  getProducts() {
    this.subscription = this.ProductService.getProducts(
      this.form.value.per_page,
      this.currentPage
    ).subscribe({
      next: (res: any) => {
        this.products = res.data.data;
        this.totalItems = res.data.total;
        this.itemsPerPage = res.data.per_page;
      },
      error: (err: any) => {
        this.toastr.error(err.message);
      },
    });
  }

  openConfirmationModal() {
    if (this.ProductService.selectedProducts.length != 0) {
      this.bsModalRef = this.modalService.show(ConfirmProductsModalComponent, {
        class: 'confirm-products-modal',
      });
    }
    this.bsModalRef?.onHidden?.subscribe((reason: any) => {
      if (reason === 'ProductsConfirmed') {
        this.getProducts();
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
