import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ActiveProductsComponent } from './active-products/active-products.component';
import { ProductsRoutingModule } from './products-routing.module';
import { ProductsComponent } from './products.component';
import { PendingProductsComponent } from './pending-products/pending-products.component';
import { ProductsTableComponent } from '../../components/products-table/products-table.component';

@NgModule({
  declarations: [
    ProductsComponent,
    ActiveProductsComponent,
    ActiveProductsComponent,
    PendingProductsComponent,
  ],
  imports: [CommonModule, ProductsRoutingModule, ProductsTableComponent],
})
export class ProductsModule {}
