import { Component } from '@angular/core';
import { CelebrityService } from '../../../services/celebrity.service';

@Component({
  selector: 'lib-pending-products',
  templateUrl: './pending-products.component.html',
  styleUrl: './pending-products.component.scss',
})
export class PendingProductsComponent {
  pendingProducts: any[] = [];

  constructor(private celebrityService: CelebrityService) {}

  ngOnInit() {
    this.celebrityService.getPendingProduct().subscribe({
      next: (res) => {
        this.pendingProducts = res.data.data;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}
