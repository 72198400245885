import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'libs/shared-lib/src/lib/services/auth/auth.service';
import { BankAccountService } from 'libs/shared-lib/src/lib/services/bank-account.service';
import { CashoutService } from 'libs/shared-lib/src/lib/services/cashouts.service';
import { ValidateFormService } from 'libs/shared-lib/src/lib/services/validate-form.service';
import { ToastrService } from 'ngx-toastr';
import { catchError, Observable, of, Subscription } from 'rxjs';
import { CelebrityService } from '../../../../services/celebrity.service';

@Component({
  selector: 'lib-withdraw-request',
  templateUrl: './withdraw-request.component.html',
  styleUrl: './withdraw-request.component.scss',
})
export class WithdrawRequestComponent {
  withdrawForm: FormGroup;
  info$: Observable<any> | undefined;
  bankAccounts$: Observable<any> | undefined;
  bankAccounts: any[] = [];
  defaultAccount: any;
  wallet: any = {};
  subscription: Subscription = new Subscription();
  recivedData: any | undefined;
  type: string | undefined;
  title: string;
  constructor(
    private fb: FormBuilder,
    private celebrityService: CelebrityService,
    private bankAccountService: BankAccountService,
    private authService: AuthService,
    private validateFormService: ValidateFormService,
    private tostar: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private cashoutService: CashoutService
  ) {
    this.withdrawForm = this.fb.group({
      amount: ['', [Validators.required, Validators.min(1)]],
      account_id: ['', Validators.required],
      wallet_id: [0, Validators.required],
    });
    const navigation = this.router.getCurrentNavigation();
    this.recivedData = navigation?.extras?.state
      ? navigation.extras.state['data']
      : null;
    this.title = this.route.snapshot.parent?.data['title'];
  }

  ngOnInit() {
    this.info$ = this.celebrityService.getCelebrityProfile().pipe(
      catchError((err: any) => {
        return of(err);
      })
    );
    this.authService.currentUser.subscribe((user) => {
      console.log('user', user);
    });
    if (this.type === 'update') {
      console.log('this.recivedData', this.recivedData);
      this.withdrawForm.patchValue({
        amount: this.recivedData.amount,
        account_id: this.recivedData.bank_account_id,
        wallet_id: this.recivedData.wallet_id,
      });
    }
    // this.bankAccounts$ = this.bankAccountService.getMyBankAccounts().pipe(
    //   catchError((err: any) => {
    //     return of(err);
    //   })
    // );
    this.getBankAccounts();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  requestWithdraw() {
    if (this.withdrawForm.invalid) {
      return this.validateFormService.validateAllFormFields(this.withdrawForm);
    }
    if (this.type === 'update') {
      this.updateCashout();
    } else {
      this.requestCashout();
    }
    console.log('this.withdrawForm', this.withdrawForm.value);
  }

  getBankAccounts() {
    this.subscription = this.bankAccountService
      .getMyBankAccounts(this.title)
      .subscribe({
        next: (res) => {
          this.bankAccounts = res.data.bank_accounts;
          this.defaultAccount = res.data.default_bank_account;
          this.wallet = res.data.internal_wallet;
          if (this.type != 'update') {
            this.withdrawForm.patchValue({
              account_id: this.defaultAccount.id,
              wallet_id: this.wallet?.id,
            });
          }
        },
        error: (err) => {
          console.log('err', err);
        },
      });
  }
  requestCashout() {
    this.cashoutService
      .requestCashout(this.withdrawForm.value, this.title)
      .subscribe({
        next: (res) => {
          this.info$ = this.celebrityService.getCelebrityProfile().pipe(
            catchError((err: any) => {
              return of(err);
            })
          );
          this.getBankAccounts();
          this.tostar.success('Withdraw request sent successfully');
        },
        error: (err) => {
          this.tostar.error(err.error.message);
          console.log('err', err);
        },
      });
  }
  updateCashout() {
    this.cashoutService
      .updateCashout(this.recivedData.id, this.withdrawForm.value, this.title)
      .subscribe({
        next: (res) => {
          this.info$ = this.celebrityService.getCelebrityProfile().pipe(
            catchError((err: any) => {
              return of(err);
            })
          );
          this.getBankAccounts();
          this.tostar.success('Withdraw request updated successfully');
        },
        error: (err) => {
          console.log('err', err);
        },
      });
  }
}
