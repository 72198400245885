<div>
    <div class="card">
        <div class="card-body p-0">
            <div class="card-header d-flex align-items-center justify-content-between"
                [ngClass]="{'mb-3': products.length>0 }">
                <h5 class="mb-0">Products</h5>
                <div>
                    <a routerLink="/celebrities/add-products" class="btn btn-primary">
                        Add Product
                        <i class="flaticon-plus fs-13 ms-2"></i>
                    </a>
                </div>
            </div>
            <lib-products-table [products]="products" [type]="'active'"></lib-products-table>
        </div>
    </div>
</div>