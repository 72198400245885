import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/src';
import { Observable } from 'rxjs';
import { CelebrityResponse } from '../types/celebrity-dashboard.types';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http_client: HttpClient) {}

  getCompanyProfile(): Observable<CelebrityResponse> {
    const URL = `${environment.BASE_URL}seller/my-profile`;
    return this.http_client.get<CelebrityResponse>(URL);
  }
  getCompanyShop(): Observable<CelebrityResponse> {
    const URL = `${environment.BASE_URL}seller/shop-profile`;
    return this.http_client.get<CelebrityResponse>(URL);
  }
  updateCompanyProfile(data: any) {
    const URL = `${environment.BASE_URL}seller/update-seller-profile`;
    return this.http_client.post(URL, data);
  }
}
