<div class="py-3">
    <div *ngIf="dashboard.affiliate" class="container-fluid">
        <div class="d-flex flex-wrap mb-3 justify-content-between align-items-center">
            <div class="company-info mb-3 mb-md-0 d-inline-flex align-items-center">
                <div class="company-avatar me-3">
                    <img class="object-fit-cover w-100" [src]="dashboard.affiliate.logo | customImage" alt="">
                </div>
                <div class="company-name d-inline">
                    <h4 class="d-inline">
                        {{ dashboard.affiliate.name }}
                    </h4>
                </div>
            </div>
            <div class="d-inline-flex">
                <a class="btn btn-primary p-2 px-3 d-flex justify-content-center align-items-center me-2"
                    routerLink="/celebrities/profile">
                    <span class="me-3"> Edit Profile </span>
                    <i class="flaticon-edit lh-1"></i>
                </a>
                <a class="btn btn-primary p-2 px-3 d-flex justify-content-center align-items-center"
                    routerLink="/transactions">
                    <span class="me-3"> withdraw </span>
                    <i class="flaticon-withdraw lh-1"></i>
                </a>
            </div>
        </div>
        <!-- <div class="action-buttons mb-4">
            <div class="row">
                <div class="col-lg-7">
                    <div class="row">
                        <div class="col-4">
                            <button class="btn btn-primary w-100 d-flex justify-content-center align-items-center">
                                <span class="me-2"> Reviews </span>
                                <i class="flaticon-review lh-1"></i>
                            </button>
                        </div>
                        <div class="col-4">
                            <button class="btn btn-primary w-100 d-flex justify-content-center align-items-center">
                                <span class="me-2"> Send Emails </span>
                                <i class="flaticon-mail lh-1"></i>
                            </button>
                        </div>
                        <div class="col-4">
                            <button class="btn btn-primary w-100 d-flex justify-content-center align-items-center">
                                <span class="me-2"> Email log </span>
                                <i class="flaticon-file-1 lh-1"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="card rounded-4 status-card">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <div class="number">
                                    <h3 class="text-truncate" [tooltip]="dashboard.totalSales.toFixed(2)">
                                        {{ dashboard.totalSales.toFixed(2) }}
                                    </h3>
                                </div>
                                <div class="status">
                                    <h5>
                                        Total sales items
                                    </h5>
                                </div>
                            </div>
                            <div class="icon bg-danger text-danger text-center p-1 mw-50 rounded-2">
                                <i class="flaticon-price-tag"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6" *ngIf="celebrity.affiliate">
                <div class="card rounded-4 status-card">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <div class="number">
                                    <h3 class="text-truncate">
                                        {{ celebrity.affiliate?.products.length }}
                                    </h3>
                                </div>
                                <div class="status">
                                    <h5>
                                        Total Products
                                    </h5>
                                </div>
                            </div>
                            <div class="icon bg-success-light text-center p-1 mw-50 rounded-2">
                                <i class="flaticon-box"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="card rounded-4 status-card">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <div class="number">
                                    <h3 class="text-truncate">
                                        {{ dashboard.totalOrder }}
                                    </h3>
                                </div>
                                <div class="status">
                                    <h5>
                                        Total orders
                                    </h5>
                                </div>
                            </div>
                            <div class="icon bg-success-light text-center p-1 mw-50 rounded-2">
                                <i class="flaticon-shopping-cart"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <a routerLink="/brands">
                    <div class="card rounded-4 status-card">
                        <div class="card-body">
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <div class="number">
                                        <h3 class="text-truncate">
                                            32
                                        </h3>
                                    </div>
                                    <div class="status">
                                        <h5>
                                            Total brands
                                        </h5>
                                    </div>
                                </div>
                                <div class="icon bg-yellow text-center p-1 mw-50 rounded-2">
                                    <i class="flaticon-top-brand"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="card rounded-4 status-card">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <div class="number">
                                    <h3 class="text-truncate" [tooltip]="celebrity.totalWithdraw?.toFixed(2)">
                                        {{ celebrity.totalWithdraw?.toFixed(2) }}
                                    </h3>
                                </div>
                                <div class="status">
                                    <h5>
                                        Total withdraw
                                    </h5>
                                </div>
                            </div>
                            <div class="icon bg-success text-success text-center p-1 mw-50 rounded-2">
                                <i class="flaticon-withdraw"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="card rounded-4 status-card">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <div class="number">
                                    <h3 class="text-truncate" [tooltip]="celebrity.totalAmount?.toFixed(2)">
                                        {{ celebrity.totalAmount?.toFixed(2) }}
                                    </h3>
                                </div>
                                <div class="status">
                                    <h5>
                                        Total amount
                                    </h5>
                                </div>
                            </div>
                            <div class="icon bg-blue text-center p-1 mw-50 rounded-2">
                                <i class="flaticon-wallet"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="summery">
            <div class="row">
                <div class="col-lg-6">
                    <div class="card mb-3 mb-lg-4 bg-white rounded-4">
                        <div class="card-body">
                            <div class="card-header mb-3 d-flex justify-content-between align-items-center">
                                <h5>Sales Summery</h5>
                                <!-- <form action="">
                                    <div class="form-group">
                                        <input type="text" placeholder="Datepicker" class="form-select" bsDatepicker>
                                    </div>
                                </form> -->
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-xl-4">
                                    <div class="small-card mb-lg-3 mb-2 today p-3 rounded-4">
                                        <div class="icon mb-2 rounded-5">
                                            <i class="flaticon-graph-analysis"></i>
                                        </div>
                                        <p class="KD"> {{ dashboard.salesSummary.todaySales.toFixed(2) }} KD</p>
                                        <p class="info">Today's Sales</p>
                                        <small class="ratio"> +8% from yesterday</small>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4">
                                    <div class="small-card mb-lg-3 mb-2 month p-3 rounded-4">
                                        <div class="icon mb-2 rounded-5">
                                            <i class="flaticon-graph-analysis"></i>
                                        </div>
                                        <p class="KD"> {{ dashboard.salesSummary.monthlySales.toFixed(2) }} KD</p>
                                        <p class="info">Last 30 Days Sales</p>
                                        <small class="ratio"> +8% from yesterday</small>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4">
                                    <div class="small-card mb-lg-3 mb-2 year p-3 rounded-4">
                                        <div class="icon mb-2 rounded-5">
                                            <i class="flaticon-graph-analysis"></i>
                                        </div>
                                        <p class="KD">{{ dashboard.salesSummary.yearlySales.toFixed(2) }} KD</p>
                                        <p class="info">Yearly Sales</p>
                                        <small class="ratio"> +1,2% from yesterday</small>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="card mb-3 mb-lg-4 bg-white rounded-4">
                        <div class="card-body">
                            <div class="card-header mb-3 d-flex justify-content-between align-items-center">
                                <h5>Profit Summery</h5>
                                <!-- <div class="form-group">
                                    <form action="">
                                        <input type="text" placeholder="Datepicker" class="form-select" bsDatepicker>
                                    </form>
                                </div> -->

                            </div>
                            <div class="row">
                                <div class="col-md-6 col-xl-4">
                                    <div class="small-card mb-lg-3 mb-2 today p-3 rounded-4">
                                        <div class="icon mb-2 rounded-5">
                                            <i class="flaticon-graph-analysis"></i>
                                        </div>
                                        <p class="KD">{{ dashboard.todayEarning }} KD</p>
                                        <p class="info">Today's profit</p>
                                        <small class="ratio"> +8% from yesterday</small>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4">
                                    <div class="small-card mb-lg-3 mb-2 month p-3 rounded-4">
                                        <div class="icon mb-2 rounded-5">
                                            <i class="flaticon-graph-analysis"></i>
                                        </div>
                                        <p class="KD">{{ dashboard.thisMonthEarning }} KD</p>
                                        <p class="info">Last 30 Days Sales</p>
                                        <small class="ratio"> +8% from yesterday</small>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4">
                                    <div class="small-card mb-xl-0 mb-2 year p-3 rounded-4">
                                        <div class="icon mb-2 rounded-5">
                                            <i class="flaticon-graph-analysis"></i>
                                        </div>
                                        <p class="KD">{{ dashboard.thisYearEarning }} KD</p>
                                        <p class="info">Yearly profit</p>
                                        <small class="ratio"> +1,2% from yesterday</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 d-flex">
                    <div class="card mb-3 mb-lg-4 bg-white rounded-4 flex-grow-1">
                        <div class="card-body">
                            <div class="card-header mb-3 d-flex justify-content-between align-items-center">
                                <div>
                                    <h5 class="mb-0">Earning Reports</h5>
                                    <small class="text-secondary"> Yearly Earnings Overview </small>
                                </div>
                                <!-- <div class="form-group">
                                    <form action="">
                                        <input type="text" placeholder="Datepicker" class="form-select" bsDatepicker>
                                    </form>
                                </div> -->
                            </div>
                            <div class="card-body">
                                <ul class="p-0 chart__list">
                                    <li>
                                        <a role="button" [ngClass]="{active: earnings == 'Profit'}"
                                            (click)="onEarningsClicked('Profit', dashboard.profitByMonth)">
                                            <span> <i class="flaticon-dollar-sign"></i> </span>
                                            Profit
                                        </a>
                                    </li>
                                    <li>
                                        <a role="button" [ngClass]="{active: earnings == 'Orders'}"
                                            (click)="onEarningsClicked('Orders', dashboard.ordersByMonth)">
                                            <span> <i class="flaticon-shopping-cart"></i> </span>
                                            Orders
                                        </a>
                                    </li>
                                    <li>
                                        <a role="button" [ngClass]="{active: earnings == 'Sales'}"
                                            (click)="onEarningsClicked('Sales', dashboard.salesByMonth)">
                                            <span> <i class="flaticon-bar-chart"></i> </span>
                                            Sales
                                        </a>
                                    </li>
                                </ul>

                                <div id="chart">
                                    <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                                        [dataLabels]="chartOptions.dataLabels" [plotOptions]="chartOptions.plotOptions"
                                        [yaxis]="chartOptions.yaxis" [xaxis]="chartOptions.xaxis"
                                        [fill]="chartOptions.fill" [title]="chartOptions.title"></apx-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end of summary cards -->
        <div class="row">

            <!-- <div class="col-lg-6">
                <div class="card mb-3 mb-lg-4 bg-white rounded-4">
                    <div class="card-body">
                        <div class="card-header mb-3 d-flex justify-content-between align-items-center">
                            <div>
                                <h5 class="mb-0">Total Sales</h5>
                                <small class="text-secondary"> Yearly Sales Overview </small>
                            </div>
                        </div>
                        <div class="card-body">
                            <ul class="p-0 chart__list">
                                <li>
                                    <a role="button" [ngClass]="{active: earnings == 'Profit'}"
                                        (click)="onEarningsClicked('Profit', dashboard.profitByMonth)">
                                        <span> <i class="flaticon-dollar-sign"></i> </span>
                                        Profit
                                    </a>
                                </li>
                                <li>
                                    <a role="button" [ngClass]="{active: earnings == 'Orders'}"
                                        (click)="onEarningsClicked('Orders', dashboard.ordersByMonth)">
                                        <span> <i class="flaticon-shopping-cart"></i> </span>
                                        Orders
                                    </a>
                                </li>
                                <li>
                                    <a role="button" [ngClass]="{active: earnings == 'Sales'}"
                                        (click)="onEarningsClicked('Sales', dashboard.salesByMonth)">
                                        <span> <i class="flaticon-bar-chart"></i> </span>
                                        Sales
                                    </a>
                                </li>
                            </ul>

                            <div class="row">
                                <div class="col-lg-6">
                                </div>
                                <div class="col-lg-6">
                                    <div id="chart">
                                        <apx-chart [series]="salesChartOptions.series" [chart]="salesChartOptions.chart"
                                            [dataLabels]="salesChartOptions.dataLabels"
                                            [plotOptions]="salesChartOptions.plotOptions"
                                            [yaxis]="salesChartOptions.yaxis" [xaxis]="salesChartOptions.xaxis"
                                            [fill]="salesChartOptions.fill"
                                            [title]="salesChartOptions.title"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="card">
            <div class="card-body p-0">
                <div class="card-header d-flex align-items-center justify-content-between"
                    [ngClass]="{'mb-3': products.length > 0}">
                    <h5 class="mb-0">Products</h5>
                    <div>
                        <a routerLink="/celebrities/add-products" class="btn btn-primary">Add Product <i
                                class="flaticon-plus fs-13 ms-2"></i></a>
                        <a href="/products" class="btn btn-link text-black">
                            View All
                        </a>
                    </div>
                </div>
                <lib-products-table [products]="products" [type]="'active'"></lib-products-table>
            </div>
        </div>
    </div>
</div>